.footer{
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  width:100%;
  border:none;
  position: relative;
  margin-bottom: 16px;
  gap:8px;
  margin-bottom: calc(8.4vw);
  border-top:1px solid #0E0E0E;
  padding-top:4.2vw;
  box-sizing: border-box; 

  @media screen and (orientation:portrait) and (max-width:1023px) {
    margin-bottom: calc(8.4vw + 164px);
  }

  // @media screen and (orientation:portrait) and (max-width:1023px) {
  //   margin-bottom: calc(8.4vw + 164px);
  // }

  .titles-column{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap:16px;

    @media only screen and (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
    img {
      width:2.6vw;
      @media only screen and (max-width: 767px) {
        width:10vw;
      }
    }
    .titles{
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: start;
      width:45vw;
      
      h4{
        padding:0;
        margin:0;
        font-weight: 400;
        font-size: 1.4vw;
        letter-spacing: -0.01em;
        text-decoration: none;
        color:#0e0e0e;
        width:20vw;
        @media only screen and (max-width: 767px) {
          font-size: 3.7vw;
          width:40vw;
        }
      }
    }
  }

  .social-links {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    // margin-right:7.8vw;

    @media only screen and (max-width: 767px) {
      margin-top: 13.2vw;
      margin-right:4.2vw;
    }

    a{
      padding:0;
      margin:0;
      font-weight: 400;
      font-size: 1.4vw;
      letter-spacing: -0.01em;
      text-decoration: underline;
      color:#0E0E0E;

      @media only screen and (max-width: 767px) {
        font-size: 3.7vw;
      }
    }
  }

  .year {
    padding:0;
    margin:0;
    font-weight: 400;
    font-size: 1.4vw;
    letter-spacing: -0.01em;
    color:#0E0E0E;
    width:6vw;

    @media only screen and (max-width: 767px) {
      font-size: 3.7vw;
      display: none;
    }

  }
  h6 { 
    padding:0;
    margin:0;
    font-weight: 400;
    font-size: 3.7vw;
    letter-spacing: -0.01em;
    color:#0E0E0E;
    display: none;
    @media only screen and (max-width: 767px) {
      display:flex;
    }

  }

}