.talent-title-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:100%;
  border:none;
  border-radius: 16px;
  position: relative;
  margin-bottom: 16px;
  border: 1px solid #0E0E0E;
  box-sizing: border-box; 

  @media only screen and (max-width: 767px) {
    display: none;
  }
  
  .titles{
    margin:0;
    padding:0;
    margin-top:2.44vw;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom:8.5vw; 
  
  }
  .title {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    width:calc(100% - 96px);
    background-image: linear-gradient(transparent calc(100% - 1px),#0E0E0E);
    margin:0;
    padding:0;
    &:last-of-type {
      background-image:none;
    }

    @media only screen and (max-width: 1023px) {
      width:calc(100% - 8.4vw);
    }
    
    h1 {
      font-size: 13.9vw;
      line-height: 108%;
      vertical-align: top;
      font-weight: 400;
      margin:0;
      padding:0;
      // width:100%;
      display: flex;
      flex-direction: row;
      align-items: baseline;

      @media only screen and (max-width: 1280px) {
        font-size: 12.6vw;
        line-height: 108%;
      }

      @media only screen and (max-width: 1023px) {
        font-size: 15.6vw;
        line-height: 108%;
      }
     
      img { 
        height: 12vw;

        @media only screen and (max-width: 1280px) {
          height: 10vw;
        }

        @media only screen and (max-width: 1023px) {
          height: 13vw;
        }

        
      }
    }
    
   
    
  
  }

  .small-text {
    position: absolute;
    width:18vw;
    font-size: 1.66vw;
    line-height: 108%;
    left:48px;
    top:21vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    text-indent: 25%;

    @media only screen and (max-width: 1280px) {
      font-size: 1.6vw;
      line-height: 108%;
      left:48px;
    }

    @media only screen and (max-width: 1023px) {
      font-size: 2vw;
      line-height: 105%;
      left:4.2vw;
      width:24vw;
      top:22vw;
    }

   
  }

      
    
}




/* Custom version for Mobile */

.talent-title-block-mobile {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:100%;
  border:none;
  border-radius: 16px;
  position: relative;
  margin-bottom: 16px;
  border: 1px solid #0E0E0E;
  box-sizing: border-box; 

  @media only screen and (max-width: 767px) {
    display: flex;
  }
  
  .titles{
    margin:0;
    padding:0;
    margin-top:8.4vw;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom:8.5vw; 
  
  }
  .title {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: flex-start;
    width:calc(100% - 8.4vw);
    // background-image: linear-gradient(transparent calc(100% - 1px),#0E0E0E);
    margin:0;
    padding:0;
    &:last-of-type {
      background-image:none;
    }
    
    h1 {
      font-size: 17vw;
      line-height: 108%;
      vertical-align: top;
      font-weight: 400;
      margin:0;
      padding:0;
      display: flex;
      flex-direction: row;
      align-items: baseline;
      width:100%;

      img { 
        height: 14.93vw;    
      }
    }
    
   
    
  
  }

  .small-text {
   
    width:calc(100% - 8.4vw);
    font-size: 5.333vw;
    font-weight: 300;
    line-height: 108%;
    
    top:21vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    text-indent: 25%;

    border-top: 1px solid #0E0E0E;
    border-bottom: 1px solid #0E0E0E;

    padding-top:8.4vw;
    padding-bottom:8.4vw;

    margin-bottom:12.6vw;

   
  }

      
    
}


