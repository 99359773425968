.content{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  height: 100vh;
  width:calc(100% - 262px);
  left:118px;
  padding-right:36px;
  padding-left:112px;
  top:0;
  padding-top:24px;
  position: absolute;
  overflow-x:hidden;
  overflow-y:auto;
  // scroll-behavior: smooth;
  z-index: 900;
  // pointer-events: none;

  .section {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
  }

  @media only screen and (max-width: 1023px) {
    width:calc(94vw);
    left:0;
    padding-left:3vw;
    padding-right:3vw;
    padding-top:3vw;
  }
  
  &::-webkit-scrollbar{
    // width:20px;
  }

  .content-bottom {
    min-height: 96px;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media screen and (orientation:portrait) {
      padding-bottom:15vh;
    }

  }



  /* GLOBAL */
  .section-labels {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    justify-self: center;
    width:calc(100% - 96px);
    position:absolute;
    bottom:4.44vw;

    &.white {
      .section-title {
        color:#fff;
      }
      .section-number {
        color:#fff;
      }
    }

    &.grey {
      .section-title {
        color:#83807C;
      }
      .section-number {
        color:#83807C;
      }
    }

    
    

    @media only screen and (max-width: 1023px) {
     width:calc(100% - 10.2vw);
    }
    .section-title {
      font-weight: 400;
      font-size: .833vw;
      line-height: .833vw;
      letter-spacing: -0.01em;
      font-feature-settings: 'ss02' on;
      padding:0;
      margin:0;
      // margin-left:48px;

      @media only screen and (max-width: 1023px) {
        font-size: 1.1vw;
        line-height: 1.1vw;
      }

      @media only screen and (max-width: 767px) {
        font-size: 2.133vw;
        line-height: 2.133vw;
        // margin-right:4.2vw;
      }

    }

    .section-number {
      color:#0E0E0E;
      font-weight: 400;
      font-size: .833vw;
      line-height: .833vw;
      text-align: right;
      font-feature-settings: 'ss02' on;
      padding:0;
      margin:0;
      // margin-right:48px;
      letter-spacing: -0.01em;

     

      @media only screen and (max-width: 1023px) {
        font-size: 1.1vw;
        line-height: 1.1vw;
        // margin-right:4.2vw;
      }

      @media only screen and (max-width: 767px) {
        font-size: 2.133vw;
        line-height: 2.133vw;
        // margin-right:4.2vw;
      }
      
    }

  }

 
}


