.unusual-subscribe-block{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: start;
  width:100%;
  // height: 640px;
  border:none;
  position: relative;
  margin-bottom: 16px;
  gap:8px;
  // flex-shrink:1;
  // flex-grow:0;
  box-sizing: border-box; 

  input:focus::placeholder {
    color:#cccccc;
  }

  .subscribe-list-item {
    // width:100%;
    margin:0;
    padding:0;
    font-size: 9.7vw;
    font-weight: 400;
    display: flex;
    width: 100%;

    &.hide {
      display: none;
    }

   
    // flex-shrink:1;
  
    .subscribe-form {
      margin:0;
      padding:0;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items:start;
      width: 100%;
      gap:8px;

      @media only screen and (max-width: 767px) {
        flex-direction: column;
      }
    }
    
    .item {
      display: flex;
      flex-direction: column;
      justify-content:center;
      align-items: center;
      border: 1px solid #0E0E0E;
      border-radius: 40px;
      width:70vw;
      height: 4.3vw;
      overflow:hidden;
      cursor: pointer;

      @media only screen and (max-width: 767px) {
        width:100%;
        font-size: 4.2vw;
        height: 8.4vw;

        input {
          font-size: 4.2vw;
        }
      }

      .subscribe-title {
        // margin-top:24px;
        display: flex;
        flex-direction:column;
        justify-content:center;
        align-items: center;
        padding:0;
        margin: 0;
        font-size:2vw;
        line-height:2vw;
        font-weight: 400;
        background:none;
        border:none;
        width:100%;
        height: 100%;
        text-align: center;
    

        @media only screen and (max-width: 767px) {
         
          font-size: 4.2vw;
          
        }
  
      } 

    }

    .subscribe-button {
      background: #05FF00;
      border-radius: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      color:#0E0E0E;
      padding:0;
      margin: 0;
      font-size: 1.4vw;
      font-weight: 400;
      height: 4.3vw;
      flex-grow:1;
      border:none;
      width:13.9vw;
      cursor: pointer;

      @media only screen and (max-width: 767px) {
        width:100%;
        font-size: 4.2vw;
        height: 8.4vw;
      }
    }
    
  }
  
  .thanks {
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 1px solid #0E0E0E;
    border-radius: 40px;
    font-size:2vw;
    line-height:2vw;
    font-weight: 400;
    height: 4.3vw;
    display: none;
    &.show {
      display: flex;
    }

    @media only screen and (max-width: 767px) {
     
      font-size: 4.2vw;
      height: 8.4vw;

    }

  }
    
}