.landing{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width:100%;
  box-sizing: border-box; 

  .box {
    border: 1px solid #0E0E0E;
    box-sizing: border-box; 
    border-radius: 16px;
    width:100%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    margin-bottom:16px;
    position: relative;
  }

  .landing-header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top:48px;
    margin-bottom:24px;
    width:calc(100% - 96px);


    @media only screen and (max-width: 1023px) {
      margin-top:2.4vw;
      margin-bottom:4.2vw;
      margin-left:0vw;
      margin-right:0;
      width:calc(100% - 8.4vw);
    }
    

    h1 {
      padding:0;
      margin:0;
      font-weight: 400;
      font-size: 1.7vw;
      line-height:  1.7vw;

      

      @media only screen and (max-width: 1023px) {
        font-size: 1.9vw;
        line-height:  1.9vw;
      }

      @media only screen and (max-width: 768px) {
        font-size: 3.7vw;
        line-height:  3.7vw;
      }

    }

    .landing-header-row-left{
      width:auto;

    }

    .landing-header-row-right{
      width:auto;

    }
  }
  
  .landing-logo{
    width:calc(100% - 96px);
    margin:0;
    padding:0;
    height: calc(100vw - 96px);

    &.padding-fix {
      margin-top:-20vw;
      margin-bottom:-20vw;
      margin-left:-10vw;
      margin-right:-10vw;
      width:calc(100% - 96px + 10vw);
      height: calc(100% - 96px + 30vw);
    }


    @media only screen and (max-width: 1023px) {
      width:calc(100% - 8.4vw); 
      height:calc(100% - 8.4vw); 

      &.padding-fix {
        width:calc(100% - 8.4vw + 10vw);
        height:calc(100% - 8.4vw + 10vw);
      }

      
    }
  }
}