.title-block{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:100%;
  border:none;
  border-radius: 16px;
  position: relative;
  margin-bottom: 16px;
  border: 1px solid #0E0E0E;
  box-sizing: border-box; 
  
  .titles{
    margin:0;
    padding:0;
    margin-top:2.44vw;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    margin-bottom:8.4vw; 

    @media only screen and (max-width: 767px) {
      margin-bottom:54vw;
      &.ignore-bottom-height-mobile {
        margin-bottom:8.4vw;
      }
    }
  }

  .title {
    font-size: 13.88vw;
    line-height: 14.25vw;
    vertical-align: top;
    font-weight: 400;
    margin:0;
    padding:0;
    text-align-last:justify;
    width:calc(100% - 96px);
    // align-items: center;
    text-align: justify;
    background-image: linear-gradient(transparent calc(100% - 1px),#0E0E0E);
    
    @media only screen and (max-width: 1023px) {
      width:calc(100% - 8.4vw);
    }

    @media only screen and (max-width: 767px) {
      background-image:none;
      &.show-line-mobile{
        background-image: linear-gradient(transparent calc(100% - 1px),#0E0E0E);
      }
      text-align-last:left;
      font-size: 16.75vw;
      line-height: 17.6vw;
      
    }

    &:last-of-type {
      background-image:none;
    }

    &.justify {
      text-align-last:center;
    }

    &.right{
      text-align:right;
      text-align-last:unset;
      width:calc(88% - 96px);
      padding-right:12%;

      @media only screen and (max-width: 1023px) {
        width:calc(88% - 8.4vw);
      }

      @media only screen and (max-width: 767px) {
       
        text-align:left;
        text-align-last:left;
      }
    }

    &.right2{
      text-align:right;
      text-align-last:unset;
    }

    &.indent{
     text-indent: 25%;
    }

    @media only screen and (max-width: 767px) {
      &.ignore-align-mobile {
        text-align:left;
        text-align-last:left;
        right:unset;
        left:unset;
        margin-right: unset;
        margin-left:unset;
        text-indent:unset;
      }
    }
  }

  .small-text {
    position: absolute;
    width:24vw;
    font-size: 1.66666vw;
    line-height: 1.944vw;
    left:48px;
    top:20vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    text-indent: 25%;

    @media only screen and (max-width: 1023px) {
      left:4.4vw;
    }

    @media only screen and (max-width: 767px) {
      bottom:12.0vw;
      top:unset;
      width:calc(100% - 8.4vw);
      left:unset;
      font-size: 5vw;
      line-height: 5.6vw;

      border-top:1px solid #0E0E0E;
      border-bottom:1px solid #0E0E0E;
      padding-top:8vw;
      padding-bottom: 8vw;

    }
  }
}