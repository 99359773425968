.divider{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width:100%;
  border:none;
  border-radius: 16px;
  position: relative;
  margin-bottom: 16px;
  box-sizing: border-box; 

  @media only screen and (max-width: 767px) {
     border-radius: 4vw;
  }

  h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 1.5vw;
    line-height: 100%;
    letter-spacing: -0.01em;
    font-feature-settings: 'ss02' on;
    margin-top:1.66vw;
    margin-bottom: 1.66vw;
    text-align: center;

    @media only screen and (max-width: 767px) {
      font-size: 3.8vw;
      width:65%;
      margin-top:4.2vw;
      margin-bottom:4.2vw;
    }

  }
  .arrows {
    display: flex;
    flex-direction: row;

    .arrow {
      height: 100%;

      

      @media only screen and (max-width: 767px) {
        height: 110%;
        &.first {
          display: none;
        }
        &.last {
          display: none;
        }
      }

    }

    &.left {
      margin-left:48px;

      @media only screen and (max-width: 1023px) {
        margin-left:3.6vw;
      }

      @media only screen and (max-width: 767px) {
        margin-left:2.8vw;
      }
    }
    &.right {
      margin-right:48px;

      @media only screen and (max-width: 1023px) {
        margin-right:3.6vw;
      }
      @media only screen and (max-width: 767px) {
        margin-right:2.8vw;
      }
    }


  }

  


      
    
}