.divider-alt{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width:100%;
  border:none;
  position: relative;
  margin-bottom: 16px;
  gap:16px;
  box-sizing: border-box; 

  @media only screen and (max-width: 1023px) {
    gap:8px;
  }

  @media only screen and (max-width: 767px) {
    gap:4px;
  }

  .title-left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 64px;
    border: 1px solid #0E0E0E;
    font-size:.84vw;
    height:3vw;
    padding-left:2.222vw;
    padding-right:2.222vw;
    position: relative;
    box-sizing: border-box;

    @media only screen and (max-width: 767px) {
      font-size:1.0vw;
      // height: 10vw;
      // border-radius: 40px;
      // width:17vw;
    }

    @media only screen and (max-width: 767px) {
      font-size:2.6vw;
      border-radius: 40px;
      height: 10vw;
      width:17vw;
    }
  }

  .title{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0E0E0E;
    border-radius: 64px;
    margin:0;
    padding:0;
    font-weight: 400;
    font-size: 2vw;
    line-height: 100%;
    height: 3vw;
    width:100%;
    overflow: hidden;
    box-sizing: border-box;

    @media only screen and (max-width: 767px) {
      font-size:3.7vw;
      border-radius: 40px;
      height: 10vw;
    }
    
    
    .title-inner {
      display: flex;
      justify-content: center;
      align-items: center;
      width:100%;
      overflow: hidden;
      white-space: nowrap; 
      position: relative;

      .title-inner-inner {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin-top:0vw;
        padding-bottom:.2vw;
      }
    }
    
  }

  .title-right {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 64px;
    border: 1px solid #0E0E0E;
    font-size:.84vw;
    height:3vw;
    padding-left:2.222vw;
    padding-right:2.222vw;
    position: relative;
    box-sizing: border-box;

    @media only screen and (max-width: 767px) {
      font-size:1.0vw;
      // height: 10vw;
      // border-radius: 40px;
      // width:17vw;
    }

    @media only screen and (max-width: 767px) {
      font-size:2.6vw;
      height: 10vw;
      border-radius: 40px;
      width:17vw;
    }
  }
  
      
    
}