.hello-media-block{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:100%;
  // height: 640px;
  aspect-ratio: 1190/700;
  flex-shrink: 0; 
  border:none;
  border-radius: 16px;
  overflow:hidden;
  position: relative;
  margin-bottom: 16px;
  // border: 1px solid #0E0E0E;
  box-sizing: border-box; 
  
  canvas {
    cursor: pointer;
  }

  

  .wins {
    pointer-events: none;
    user-select: none;
    margin:0;
    padding:0;
    position: absolute;
    width:calc(100% - 96px);
    font-size: 13.8vw;
    line-height: 11.1vw;
    font-weight: 400;
    letter-spacing: -1%;
    font-feature-settings: 'ss02' on;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 10;
    background: transparent;
    
  }
  

      
    
}