.transition{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width:100vw;
  height: 100vh;
  position: absolute;
  z-index:100000;
  background:#F4E9E1;
  pointer-events: none;
  transform-origin: 100% 50%;

  .bar {
    width:0;
    height: 1px;
    background:#242320;
    opacity:.8;
    display: flex;
    position: absolute;

  }
}