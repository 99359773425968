.image-sequence-block{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:100%;
  // height: 640px;
  aspect-ratio: 1920/1080;
  flex-shrink: 0; 
  // flex-grow: 1;
  border:none;
  border-radius: 16px;
  position: relative;
  margin-bottom: 16px;
  // border: 1px solid #0E0E0E;
  position: relative;
  box-sizing: border-box; 

  overflow:hidden;

  .media {
    width:120%;
    height: 120%;
    object-fit: cover;
    margin-left:0%;
    margin-top:12.5%;
  }

  


      
    
}