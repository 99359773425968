.text-block{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:100%;
  border:none;
  border-radius: 16px;
  position: relative;
  margin-bottom: 16px;
  box-sizing: border-box; 
  
  .titles{
    margin:0;
    padding:0;
    margin-top:4.44vw;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: start;
    margin-bottom: 5.6vw;
  }

  .title {
    font-size: 6.94vw;
    line-height: 8.33vw;
    vertical-align: top;
    font-weight: 400;
    margin:0;
    padding:0;
    width:calc(100% - 96px);
    background-image: linear-gradient(transparent calc(100% - 1px),#0E0E0E);
    background-size: 1px 8.33vw;
    background-position-y: .69vw;
    text-indent: 12%;
    position: relative;
    padding-bottom:.5555vw;
    margin-bottom: 5.333vw;
    clip-path: inset(1.4vw 0px 0px 0px);

    @media only screen and (max-width: 1023px) {
      width:calc(100% - 10.4vw);
      font-size: 8.33vw;
      line-height: 10vw;
      background-size: 1px 10vw;
      text-indent: 15%;
      padding-right:2vw;
      margin-bottom:6vw;
    }

    @media only screen and (max-width: 767px) {
      width:calc(100% - 10.4vw);
      font-size: 10.6vw;
      line-height: 14.9vw;
      background-size: 1px 14.9vw;
    }
  
    .title-number {
      font-size: 1.25vw;
      border: 1px solid #0E0E0E;
      border-radius:1.7vw;
      position: absolute;
      top:4.16vw;
      display: flex;
      height: 1.6666vw;
      width: 3vw;
      padding-right:.5vw;
      justify-content: center;
      align-items: center;

      @media only screen and (max-width: 767px) {
        font-size: 2.5vw;
        border-radius:2.4vw;
        position: absolute;
        height: 3.2vw;
        width: 6vw;
        padding-right:1vw;
      }
    }
  }
}