.section-intro{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width:100%;
  // height: 640px;
  aspect-ratio: 1190/640;
  flex-shrink: 0; 
  border:none;
  border-radius: 16px;
  position: relative;
  margin-bottom: 16px;

  @media only screen and (max-width: 767px) {
    aspect-ratio: 343/480;
  }

  .section-number {
    font-weight: 400;
    font-size: 6.9vw;
    line-height: 6.9vw;
    text-align: right;
    font-feature-settings: 'ss02' on;
    margin:0;
    margin-left:48px;
    margin-top:4.4vw;
    letter-spacing: -0.01em;

    @media only screen and (max-width: 1023px) {
      margin-left:4.2vw;
    }

    @media only screen and (max-width: 767px) {
      margin-top:8vw;
    }

  }

  h1 {
    font-weight: 400;
    font-size: 13.8vw;
    line-height: 13.8vw;
    letter-spacing: -0.01em;
    font-feature-settings: 'ss02' on;
    padding:0;
    margin:0;
    margin-left:48px;
    margin-bottom:4.4vw;

    @media only screen and (max-width: 1023px) {
      margin-left:4.2vw;
    }

    @media only screen and (max-width: 767px) {
      margin-bottom:8vw;
    }
    
  }



      
    
}