.how-to-quit-title-block{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:100%;
  border:none;
  border-radius: 16px;
  position: relative;
  margin-bottom: 16px;
  // border: 1px solid #0E0E0E;
  box-sizing: border-box; 
  overflow:hidden;

  .background-image-mobile {
    display: none;
    @media only screen and (max-width: 767px) {
      display: flex;
      position: absolute;
      top:20vw;
      width:90vw;
      left:5.4vw;
      z-index: 0;

      &.style-2 {
        top:2.4vw;
        width:100%;
        left:0;
      }

      &.style-3 {
        top:12.4vw;
        width:100%;
        left:0;
      }

      &.style-4 {
        top:unset;
        bottom:4.8vw;
        width:100%;
        left:0;
      }

      &.style-5 {
        top: 12%;
        left:0;
        width:100%;
        z-index: 1;
      }
    }
  }

  .background-image {
    display: flex;
    position: absolute;
    z-index: 0;
    bottom:0;
    width:100vw;
    left:0;

    &.style-1 {
      bottom:0;
      width:95.5%;
      left:0;
      
    }

    &.style-2 {
      bottom:0;
      width:100%;
      left:0%;
      
    }

    &.style-3 {
      bottom:0;
      width:98%;
      left:1%;
      
    }

    &.style-4 {
      bottom: 12%;
      width: 88%;
      left: 32%;
      z-index: 1;
      
    }

    &.style-5 {
      bottom:0;
      width:100%;
      left:0%;
      z-index: 1;
    }



    @media only screen and (max-width: 767px) {
      display: none;
    }
  }
  
  .titles{
    margin:0;
    padding:0;
    z-index: 1;
    margin-top:2.44vw;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: flex-start;


    &.bottom-blocks-2 {
    
    }



    @media only screen and (max-width: 767px) {
      &.bottom-blocks-2 {
        margin-bottom: unset;
      }


      
      margin-top:8.4vw;
      margin-bottom:12vw;
      // margin-bottom:54vw;
      &.ignore-bottom-height-mobile {
        margin-bottom:8.4vw;
      }

      &.bottom-blocks-3 {
        margin-bottom: 24vw;
      }

      &.bottom-blocks-3a {
        margin-bottom: 2.4vw;
      }


      &.mobile-blocks-margin-1 {
        margin-bottom:96vw;
      }

      &.mobile-blocks-margin-2 {
        margin-bottom:46vw;
      }

      &.mobile-blocks-margin-4 {
        margin-bottom:0;
        
      }

      &.mobile-blocks-margin-5 {
        margin-bottom:118vw;
      }

    }
  }

  .title {
    font-family: "HTQ-Waldenburg-FettSchmal";
    font-feature-settings: 'case' on;
    font-feature-settings: 'case' on;
    letter-spacing: -.0277vw;
    font-size: 11.4vw;
    line-height: 14.25vw;
    vertical-align: top;
    font-weight: 500;
    margin:0;
    padding:0;
    text-align-last:justify;
    width:calc(100% - 96px);
    // align-items: center;
    text-align: justify;
    background-image: linear-gradient(transparent calc(100% - 1px),#0E0E0E);

    &.green-line {
      background-image: linear-gradient(transparent calc(100% - 1px),#3EEA5A);
      .title-number {
        color: #3EEA5A;
        border: 1px solid #3EEA5A;
      }
    }

    &.hideOnDesktop {
      display: none;
    }
    
    @media only screen and (max-width: 1023px) {
      width:calc(100% - 8.4vw);
    }

    @media only screen and (max-width: 767px) {
      background-image:none;
      &.show-line-mobile{
        background-image: linear-gradient(transparent calc(100% - 1px),#0E0E0E);
      }
      &.green-line {
        background-image: linear-gradient(transparent calc(100% - 1px),#3EEA5A);
      }
      text-align-last:left;
      font-size: 17vw;
      line-height: 20vw;
      letter-spacing: -.6vw;

      &.hideOnMobile {
        display: none;
      }

      &.hideOnDesktop {
        display: flex;
      }
      
    }

    @media only screen and (min-width: 1280px) {
      font-size: 13.4vw;
    }

   

    &:last-of-type {
      background-image:none;
    }

    &.justify {
      // text-align-last:center;
    }

    &.left {
      text-align: left;
      justify-content: flex-start;
      text-align-last:left;
    }

    &.left2 {
      text-align: left;
      justify-content: flex-start;
      text-align-last:left;
      width:calc(94% - 96px);
      padding-left:6%;
      
    }

    &.left3 {
      text-align: left;
      justify-content: flex-start;
      text-align-last:left;
      width:calc(88% - 96px);
      padding-left:12%;


    }

    &.left4 {
      text-align: left;
      justify-content: flex-start;
      text-align-last:left;
      width:calc(76% - 96px);
      padding-left:46%;
    }
    

    &.right{
      text-align:right;
      text-align-last:unset;
      width:calc(100% - 96px);
      // padding-right:12%;

      @media only screen and (max-width: 1023px) {
        width:calc(88% - 8.4vw);
      }

      @media only screen and (max-width: 767px) {
       
        text-align:left;
        text-align-last:left;
      }
    }

    &.right2{
      text-align:right;
      text-align-last:unset;
      width:calc(94% - 96px);
      padding-right:6%;
    }

    &.right3{
      text-align:right;
      text-align-last:unset;
      width:calc(80% - 96px);
      padding-right:20%;
    }

    &.right4{
      text-align:right;
      text-align-last:unset;
      width:calc(70% - 96px);
      padding-right:30%;
    }

    &.right5{
      text-align:right;
      text-align-last:unset;
      width:calc(60% - 96px);
      padding-right:40%;
    }

    &.indent{
     text-indent: 25%;
    }

   

    @media only screen and (max-width: 767px) {
      &.ignore-align-mobile {
        text-align:left;
        text-align-last:left;
        right:unset;
        left:unset;
        margin-right: unset;
        margin-left:unset;
        text-indent:unset;
        padding-right: unset;
        padding-left: unset;
        width:calc(100% - 8.4vw);
      }

      &.mobile-left {
        text-align:left;
        text-align-last:unset;
        width:calc(100% - 8.4vw);
        padding:0;
      }

      &.mobile-right {
        text-align:right;
        text-align-last:unset;
        width:calc(100% - 8.4vw);
      }

      &.mobile-right3{
        text-align:right;
        text-align-last:unset;
        width:calc(80% - 8.4vw);
        padding-right:20%;
      }

      &.unsetWidth {
        width: unset;
      }
    }
  }

  .title-number {
    font-size: .97vw;
    border: 1px solid #0E0E0E;
    border-radius:1.7vw;
    position: absolute;
    top:unset;
    // bottom: -12vw;10vw;
    left:2.4vw;
    top:13.1vw;
    display: flex;
    height: 1.527vw;
    width:2.8vw;
    

    // padding-right:.625vw;
    // padding-left: .625vw;
    justify-content: center;
    align-items: center;
   
    letter-spacing: -.05em;

    @media only screen and (max-width: 767px) {
      font-size: 2.5vw;
      border-radius:2.4vw;
      position: absolute;
      height: 3.2vw;
      width: 6vw;
      padding-right:1vw;
      padding-left: 0.5vw;
      left:4.8vw;
      top:20.8vw;
      letter-spacing:0;
    }
  }

  .small-texts { 
    text-rendering: geometricPrecision;
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width:calc(100% - 96px);
    margin-bottom:8.4vw;
    margin-top:4.8vw;

    @media only screen and (max-width: 767px) {
      flex-direction:column;
      gap:4.8vw;
      width:100%;
      margin-left:8.4vw;
    }
  }

  .small-text {
    
    width:38vw;
    font-size: 1.66vw;
    line-height: 1.9vw;
    right:48px;
    display: flex;
    text-align: left;
    text-indent: 6.4vw;
    // color:#0E0E0E;
    text-rendering: geometricPrecision;

    &.white {
      color:#fff;
    }
    // font-family: "KlarheitKurrent";
    letter-spacing: -0.01666vw;
    font-weight:400;
    font-feature-settings: 'ss02' on;
    gap:1.2vw;
    @media only screen and (max-width: 767px) {
      gap:4.8vw;
    }

    p{
      margin:0;
      padding:0;
      
    }

    &.bottom-blocks-2 {
      left:48px;
      display: flex;
      flex-direction: column;
      width:30.5vw;
      @media only screen and (max-width: 767px) {
        height: unset;
      }

    }

    &.bottom-blocks-3 {
      width:32vw;
    }

    &.bottom-blocks-4 {
      margin-left:4.8vw;
      display: flex;
      flex-direction: column;
      width:32vw;
      @media only screen and (max-width: 767px) {
        height: unset;
      }

    }

    

   

    @media only screen and (max-width: 1023px) {
      // left:4.4vw;
    }

    @media only screen and (max-width: 767px) {

      &.bottom-blocks-2 {
        left:0vw;
        bottom:unset;
        width:calc(100% - 8.4vw);
      }

      &.bottom-blocks-3 {
        left:0vw;
        bottom:unset;
        width:calc(100% - 8.4vw);
      }

      &.bottom-blocks-3a {
        left:0vw;
        bottom:unset;
        width:calc(100% - 8.4vw);
      }

      &.bottom-blocks-4 {
        left:0vw;
        margin-left:unset;
        bottom:unset;
        width:calc(100% - 8.4vw);
        margin-bottom: 84vw;
      }

      position: relative;
      top:unset;
      width:calc(100% - 10vw);
      left:0vw;
      font-size: 5.6vw;
      line-height: 6.4vw;
      text-indent: 12%;
      justify-content: flex-start;
      align-items: flex-start;

      // border-top:1px solid #0E0E0E;
      // border-bottom:1px solid #0E0E0E;
      padding-top:8vw;
      padding-bottom: 8vw;

    }


    &.special-small-text-1 {
      position: absolute;
      top:-51vw;
      left:6.2vw;
      z-index: 10;
      width:30vw;

      @media only screen and (max-width: 767px) {
        position: relative;
        top:unset;
        left:unset;
        z-index: 10;
        width:usnet;
        width:calc(100% - 8.4vw);
        left:0vw;
      }
    }

  }


  .small-text-2 {
    text-rendering: geometricPrecision;
    width:22vw;
    font-size: 1.66vw;
    line-height: 1.9vw;
    right:48px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: left;
    text-indent: 6.4vw;
    // font-family: "KlarheitKurrent";
    letter-spacing: -0.01666vw;
    font-weight:400;
    font-feature-settings: 'ss02' on;
    gap:1.2vw;
    @media only screen and (max-width: 767px) {
      gap:4.8vw;
    }
    p{
      margin:0;
      padding:0;
      
    }

    &.bottom-blocks-2 {
      right:48px;
     
      width:30.5vw;
      display: flex;


      @media only screen and (max-width: 767px) {
        height: unset;
      }

    }

    &.bottom-blocks-3 {
      right:48px;
      width:30vw;
      display: flex;
      @media only screen and (max-width: 767px) {
        height: unset;
      }

    }

    &.bottom-blocks-3a {
      right:48px;
      width:30vw;
      display: flex;
      @media only screen and (max-width: 767px) {
        height: unset;
      }

    }

   

    @media only screen and (max-width: 1023px) {
      // left:4.4vw;
    }

    @media only screen and (max-width: 767px) {
      position: relative;
      top:unset;
      width:calc(100% - 10vw);
      left:0vw;
      font-size: 5.6vw;
      line-height: 6.4vw;
      text-indent: 12%;
      justify-content: flex-start;
      align-items: flex-start;

      &.bottom-blocks-2 {
        left:0vw;
        bottom:8.4vw;
        width:calc(100% - 8.4vw);
      }


      &.bottom-blocks-3 {
        left:0vw;
        bottom:8.4vw;
        width:calc(100% - 8.4vw);
      }

      &.bottom-blocks-3a {
        left:0vw;
        bottom:8.4vw;
        width:calc(100% - 8.4vw);
      }


      // border-top:1px solid #0E0E0E;
      // border-bottom:1px solid #0E0E0E;
      padding-top:8vw;
      padding-bottom: 8vw;

    }
  }

  &.special-small-text-1 {
    .small-texts {
      margin-top: 13vw;
    }
  }
  
}