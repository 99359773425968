.case-study-preview-card{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width:100%;
  height: 11.1vw;
  // height: 20.83vw;
  color:#F4E9E1;
  margin-bottom:16px;
  position: relative;
  perspective: 3000px;
  perspective-origin: 50% 0%;
  overflow: visible;
  pointer-events: all;
  transition:height .3s ease-in-out;

  &.clickable {
    cursor: pointer;
  }


  
  &.open{
    height:20.83vw;
  }

  @media only screen and (max-width: 767px) {
    pointer-events: none;
    height:24vw;
    &.open {
      height:45vw;
    }

    &.mobile-disable-clickable {
      pointer-events: none !important;
      touch-action: none !important;
      .case-study-preview-card-front {
        pointer-events: none;
      }
    }
  }
  
  .case-study-preview-card-front {
    display: flex;
    width:100%;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    z-index: 200;
    background: #0E0E0E;
    // border: 0.5px solid #83807C;
    border-radius: 16px;
    height: 11.1vw;
    // height: 20.83vw;
    padding:1px;

    overflow:hidden;
    transform-origin: 50% 100%;
    transform: rotateX(0deg);
    pointer-events: all;
    
    transition:height .3s ease-in-out;

    &.open{
      height:20.83vw;
    }
  
    @media only screen and (max-width: 767px) {
      height:24vw;
      
      &.open {
        height: 45vw;
      }

      

    }

    .title-row{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      margin-top:3.4vw;
      margin-left:48px;
      user-select: none;
      width:calc(100% - 96px);

      @media only screen and (max-width: 1023px) {
        margin-left:4.2vw;
        // margin-top:4.2vw;
        // align-items: flex-start;
        width:unset;
       
      }

      @media only screen and (max-width: 767px) {
        margin-top:8.4vw;
        align-items: flex-start;
        width:unset;
      }

      

      .title-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        

        @media only screen and (max-width: 767px) {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
        }
        

        h1 {
          font-size: 2.22vw;
          line-height: 2.22vw;
          margin:0;
          padding:0;
          font-weight: 400;
         
          position: absolute;
          left:48px;
          display: flex;
  
          
  
          @media only screen and (max-width: 767px) {
            font-size: 5.33vw;
            line-height: 5.33vw;
            width:unset;
            position: unset;
          }
  
        }
      
        .mobile-category {
          font-size:1.388vw;
          font-weight: 400;
          color:#83807C;
          &.disabled{
            opacity:.4;
            border: 1px solid #83807C;
            padding:.4vw;
            padding-left:1.4vw;
            padding-right:1.4vw;
            border-radius: 24px;
          }
          display: none;
          margin-top:4.2vw;
          
          @media only screen and (max-width: 1023px) {
            
            left:4.2vw;
          }
          @media only screen and (max-width: 767px) {
            font-size: 3.2vw;
            display: flex;
            margin-top:1vw;
          }
        }
      }
    
      .category {
        font-size:1.388vw;
        font-weight: 400;
        color:#83807C;
        margin:0;
        margin-top:1.2vw;
        margin-bottom:1.2vw;
        user-select: none;
        
        text-align: center;
        
        &.disabled{
          opacity:.4;
          border: 1px solid #83807C;
          padding:.4vw;
          font-size:1vw;
          text-transform: uppercase;
          padding-bottom:.6vw;
          padding-left:1.4vw;
          padding-right:1.4vw;
          border-radius: 24px;
          margin-left:-.2vw;
          // margin-top:.6vw;
          margin-top:.7vw;
          margin-bottom:.7vw;
          box-sizing: border-box; 
        }
        

        @media only screen and (max-width: 767px) {
          font-size: 3.2vw;
          display: none;
        }
      }

      .case-study-preview-card-icon {
        position: absolute;
        right:48px;
        object-fit: contain;
        margin:0;
        padding:0;

        @media only screen and (max-width: 1023px) {
          right:4.2vw;
          // top:8.4vw;
          // bottom:8.4vw;
        }
        &.h48 { 
          height: 3.33vw;
          @media only screen and (max-width: 767px) {
            height: 8.5vw;
            // top:4.2vw;
          
          }
        }
        &.h32 { 
          height: 2.22vw;
          @media only screen and (max-width: 767px) {
            height: 4.266vw;
          }
        }
        &.h16 { 
          height: 1.11vw;
          @media only screen and (max-width: 767px) {
            height: .8666vw;
          }
        }
      }
    }

    h3 {
      padding:0;
      margin:0;
      margin-left:48px;
      font-size: 2.77vw;
      line-height: 3.05vw;
      letter-spacing: -0.01em;
      font-weight: 400;
      width:64vw;
      margin-top:3.0vw;

      @media only screen and (max-width: 1023px) {
        margin-left:4.2vw;

      }

      @media only screen and (max-width: 767px) {
        font-size:5vw;
        line-height:5vw;
        width:calc(100% - 10vw);
      }


    }
  
  }
 
  .case-study-preview-card-thumb {
    position: absolute;
    left:0;
    top:0;
    border-radius: 16px;
    // border: 0.5px solid #83807C;
    height: 11.1vw;
    object-fit: cover;
    width:100%;
    z-index: 1;
    transform-origin: 50% 100%;
    transform: rotateX(0deg);
    transition:height .3s ease-in-out;

    &.open{
      height:20.83vw;
    }
  
    @media only screen and (max-width: 767px) {
      height:0vw;
      &.open {
        height: 0vw;
      }
    }
    
  }

}