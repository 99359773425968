.volta-bar-graph{
  display:flex;
  justify-content: center;
  align-items: center;
  width:100%;
  border:none;
  border-radius: 16px;
  position: relative;
  margin-bottom: 16px;
  background-color: #D9D9D9;
  overflow:hidden;
  aspect-ratio: 1190/600;
  box-sizing: border-box; 

  @media only screen and (max-width: 767px) {
    aspect-ratio: 1190/800;
  }

  .volta-bars {
    width:calc(100% - 32px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap:16px;
    height: calc(100% - 32px);

    @media only screen and (max-width: 767px) {
      width:calc(100% - 4.8vw);
      height: calc(100% - 4.8vw);
      gap:2.4vw;
    }
  }

  .volta-bar {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    background-color: #B6C9CE;
    border-radius: 16px;
    height: 100%;

    @media only screen and (max-width: 767px) {
      border-radius: 2.4vw;
    }

    .volta-bar-inner {
      width:100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 16px;
      height: 50%;
      position: relative;

      @media only screen and (max-width: 767px) {
        border-radius: 2.4vw;
      }

      .arrow {
        position: absolute;
        top:2vw;
        width:.6vw;

        @media only screen and (max-width: 767px) {
          width:1.4vw;
        }
      }

      h1 {
        padding:0;
        margin:0;
        font-weight: normal;
        font-size: 1vw;
        text-align: center;
        width:100%;
        @media only screen and (max-width: 767px) {
          font-size: 1.6vw;
          width:100%;
        }
      }

      h2 {
        position: absolute;
        bottom:2vw;
        width:.6vw;
        padding:0;
        margin:0;
        font-weight: normal;
        font-size: 1vw;
        text-align: center;
        width:100%;
        @media only screen and (max-width: 767px) {
          font-size: 1.6vw;
          width:100%;
        }
      }
    }


  } 
    
}