.mission-block{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:100%;
  // height: 640px;
  // aspect-ratio: 1190/1200;
  // flex-shrink: 0; 
  border:none;
  border-radius: 16px;
  position: relative;
  margin-bottom: 16px;
  border: 1px solid #0E0E0E;
  box-sizing: border-box; 
  
  .rows{
    margin:0;
    padding:0;
    margin-top:4.44vw;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;

    margin-bottom:4.44vw;

    @media only screen and (max-width: 767px) {
      margin-top:8.4vw;
    }

    .row {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom:3.33vw;
      width:calc(100%);

      @media only screen and (max-width: 767px) {
        flex-direction: column;
      }




      .cell {
        display:flex;
        @media only screen and (max-width: 767px) {
          width: calc(100%);

        }
        &.first-row{
          @media only screen and (max-width: 767px) {
            display: none;
  
          }
        }
      }

      .title {
        font-size: 2vw;
        line-height: 2.2vw;  
        font-weight: 400;
        margin:0;
        padding:0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left:48px;
        width:calc(19.4vw - 48px);
        // padding-right:15vw;
        margin-top:1.7vw;
        
        @media only screen and (max-width: 1023px) {
          margin-left:4.2vw;
        }

        @media only screen and (max-width: 767px) {
          width: calc(100% - 8.4vw);
          margin-left:13.4vw;
          flex-direction: row;
          align-items: center;
          font-size:4.2vw;
          margin-bottom:4.2vw;
          margin-top:4.2vw;
        }
        

        .title-number {
          margin:0;
          padding:0;
          font-size: 1.3vw;
          border: 1px solid #0E0E0E;
          border-radius: 1.7vw;
          margin-bottom:.5vw;
          
          height: 1.7vw;
          width: 3vw;
          padding-right:.27vw;
          display: flex;
          justify-content: center;
          align-items: center;


          @media only screen and (max-width: 767px) {
            font-size: 2.5vw;
            border-radius:2.4vw;
            position: absolute;
            height: 3.2vw;
            width: 6vw;
            padding-right:1vw;
            margin-left:-9.2vw;
            margin-top:.5vw
            
          }
         
          
        }


      }

      .text {
        font-weight: 400;
        letter-spacing: -0.01em;
        font-size: 7vw;
        line-height: 7vw;
        margin:0;
        padding:0;
        width:56.5vw;
        // padding-right:6vw;
        margin-right:48px;
        padding-bottom:4vw;
        border-bottom: 1px solid #0E0E0E;

        &.no-border {
          border-bottom: none;
        }

        @media only screen and (min-width: 1600px) {
          font-size: 7.4vw;
          line-height: 7.4vw;
          width:63vw;
        }

        @media only screen and (max-width: 1196px) {
          font-size: 6.4vw;
          line-height: 6.4vw;
          width:52vw;
        }


        @media only screen and (max-width: 1023px) {
          font-size: 8.3vw;
          line-height: 8.3vw;
          width:66.5vw;
          margin-right:4.2vw;
          margin-left:6.4vw;
        }
        
        @media only screen and (max-width: 767px) {
          width: calc(100% - 8.4vw);
          font-size: 10.2vw;
          line-height: 10.2vw;
          padding-right:8vw;
          margin-left:4.2vw;
          padding-bottom:8vw;
        }



      }

     

    }


    
  }

  

 
      
    
}