.people-row{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width:100%;
  position: relative;
  margin-bottom: 16px;
  gap:16px;
  height:auto;
  overflow-y: hidden;
  overflow-x: auto;
  flex-shrink: 0;
  

  .people-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 16px;
    border: 1px solid #0E0E0E;
    width:calc(33.33%);
    position: relative;
    flex-shrink: 1;
    overflow:hidden;
    box-sizing: border-box; 
    
    @media only screen and (max-width: 767px) {
      width:calc(75%);
      flex-shrink: 0;
    }

    h3 {
      margin:0;
      padding:0;
      font-size:1.6vw;
      font-weight: 400;
      margin-top:32px;
      z-index:1;

      @media only screen and (max-width: 767px) {
        font-size: 5vw;
        line-height: 5vw;
      }
    }

    .media {
      margin:0;
      padding:0;
      width:100%;
      margin-top:-4vw;
      margin-bottom:0vw;
      z-index:0;
      transform: translateZ(0);

      &.video {
        video {
          width:100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }


    img {
      margin:0;
      padding:0;
      width:57%;
      margin-top:-12px;
      margin-bottom:32px;
    }

    h4 {
      margin:0;
      padding:0;
      position: absolute;
      bottom:32px;
      width:50%;
      font-size:3.2vw;
      line-height: 3.2vw;
      font-weight: 400;
      left:48px;

      @media only screen and (max-width: 767px) {
        font-size: 8vw;
        line-height: 8vw;
      }
    }
  }

      
    
}