.media-block-animated-volta{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:100%;
  aspect-ratio: 1190/1075;
  border:none;
  border-radius: 16px;
  position: relative;
  margin-bottom: 16px;
  margin-top:-14vw;
  z-index: 1000;
  box-sizing: border-box; 

 

  &.full-bleed{
    flex-shrink: unset; 

  }

  &.tight {
    aspect-ratio: unset;
  }

  @media only screen and (max-width: 767px) {
    &.mobileRotate90 {
      height:100vw;
      flex-grow: 1;
      flex-shrink: 0;
    }
  }

  .animated-background {
    position: absolute;
    bottom:0;
    width:100%;
    height: 10%;
    border-radius: 16px;
    overflow:hidden;
    @media only screen and (max-width: 767px) {
      padding-top:3%;
    }
    
  }

  .animated-fill-block {
    position: absolute;
    bottom:24.27vw;
    left:25.8vw;
    width:22.9vw;
    height: 10vw;
    border-radius: 16px;
    z-index: 100;
    overflow:hidden;

    @media only screen and (max-width: 767px) {
      bottom:25.27vw;
      left:32vw;
      width:26vw;
      height: 10vw;
      padding-top:5vw;
      border-radius: 8px;
    }

    .counter-small {
      padding:0;
      margin:0;
      position: absolute;
      top:4.2vw;
      right:2.4vw;
      font-size: 8vw;
      font-weight: 500;
      .small { 
        font-size: 4vw;
      }
    }
  }

  .counter {
    padding:0;
    margin:0;
    position: absolute;
    top:4.2vw;
    right:4.2vw;
    font-size: 16vw;
    font-weight: 500;
    .small { 
      font-size: 8vw;
    }
  }

  .lf-player-container {
    // width:inherit;
    padding:0;
    margin:0;
  }
  
  h1 {
    margin:0;
    padding:0;
    font-size:8.3vw;
    font-weight: 400;
    margin-bottom:-4vw;
  }

  .media {
    width:82%;
    height: auto;
    margin-top:1.666vw;

    &.mobile-hide{
      @media only screen and (max-width: 767px) {
        display: none;
      }
    }
    

    &.tight {

    }

    &.larger {
      width:91%;
      margin-top:0px;
    }
    &.full {
      width:calc(100% - 96px);
      padding:0;
      margin: 0;
      margin-left:48px;
      margin-right:48px;
    }
    &.full-bleed {
      width:calc(100%);
      object-fit: cover;
      padding:0;
      margin: 0;
     
    }

    &.full-bleed-plus {
      width:48vw;
      left:22vw;
      object-fit: contain;
      bottom:0;
      position: absolute;
      padding:0;
      margin: 0;
      z-index: 100;
    

    }

    &.topPad {
      
      margin-top:4.44vw;
    }

    &.bottomPad {
      margin-bottom:4.44vw;
    }
    &.topPadLarger {
      
      margin-top:9vw;
    }

    &.bottomPadLarger {
      margin-bottom:9vw;
    }
    &.offset-top{
      margin-top:-11vw;
    }

    &.offset-top-big{
      margin-top:-30vw;
    }
    &.inset-80 {
      width:80vw;
    }

    
    @media only screen and (max-width: 767px) {
      &.mobileRotate90 {
        transform:rotate(90deg);
        width:96vw;
      }
      &.paddingBottomMobile {
        margin-bottom:8.4vw;
      }
  
    }
    
  }


  

  .media-mobile {
    width:82%;
    height: auto;
    margin-top:1.666vw;
    display: none;

    @media only screen and (max-width: 767px) {
      display: block;
    }

    &.tight {

    }

    &.larger {
      width:91%;
      margin-top:0px;
    }
    &.full {
      width:calc(100% - 96px);
      padding:0;
      margin: 0;
      margin-left:48px;
      margin-right:48px;
    }
    &.full-bleed {
      width:calc(100%);
      object-fit: cover;
      padding:0;
      margin: 0;
      
    }
    &.topPad {
      
      margin-top:4.44vw;
    }

    &.bottomPad {
      margin-bottom:4.44vw;
    }
    &.topPadLarger {
      
      margin-top:9vw;
    }

    &.bottomPadLarger {
      margin-bottom:9vw;
    }
    &.offset-top{
      margin-top:-11vw;
    }

    &.offset-top-big{
      margin-top:-30vw;
    }
    &.inset-80 {
      width:80vw;
    }

    &.full-bleed-plus {
      width:56vw;
      left:27vw;
      object-fit: contain;
      bottom:0;
      position: absolute;
      padding:0;
      margin: 0;
      z-index: 100;
    

    }
    
  }

  


      
    
}