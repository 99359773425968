.navigation{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  left:0;
  background: transparent;
  // padding:10px;
  width:226px;
  height: 100vh;
  overflow-y:auto;
  z-index: 1200;
  // scroll-behavior: smooth;
  &::-webkit-scrollbar{
    width: 0;
    width:none;
  }

  @media only screen and (max-width: 1023px) {
    display:none;
  }



  nav {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap:12px;
    width:100%;
    margin-top:24px;
    margin-bottom:24px;

    .nav-item {
      transition: background .3s ease-in-out, border .3s ease-in-out, color .3s ease-in-out,filter .3s ease-in-out ;
      border: 1px solid transparent;
      margin:0;
      padding: 0; 
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      width:178px;
      border-radius: 16px;
      min-height: 104px;
      pointer-events: all;
      cursor: pointer;

      .nav-item-number {
        margin-left:16px;
        margin-top:16px;
        font-size:12px;
      }
      .nav-item-text {
        margin-bottom:16px;
        margin-left:16px;
        font-size: 18px;
      }

      .nav-dot{
        position: absolute;
        right:8px;
        border-radius: 50%;
        margin:0;
        padding:0;
        width: 6px;
        height: 6px;
        opacity: 0;
        top:calc((100% - 32px) * 0.5 + 16px);
      }
      // .nav-dot-0{ display: none;}

      &:hover:not(.active){
        background: none;
        border: 1px solid #0E0E0E;
        color:#0E0E0E;
      }

      .lottie {
        margin-top:-14px;
        position: absolute;
        width:calc(75%);
        align-self: center;
        justify-self: center;
        margin-left:4px;
        opacity:0;

      }

    }
    
  }
  
  
}

.greyBackground {
  .navigation{
    nav {
      .nav-item {
        &:hover:not(.active){
          background: none;
          border: 1px solid #F4E9E1;
          color:#F4E9E1;
        }
      }
    }
  }
}

