.tabbed-block{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width:100%;
  // height: 640px;
  aspect-ratio: 1190/630;
  flex-shrink: 0; 
  border:none;
  border-radius: 16px;
  position: relative;
  margin-bottom: 16px;
  border-left: 1px solid #0E0E0E;
  border-right: 1px solid #0E0E0E;
  border-bottom: 1px solid #0E0E0E;
  overflow:hidden;
  padding-top:2px;

  @media only screen and (max-width: 767px) {
    display: none;
  }
  
  .tabs{
    margin:0;
    padding:0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width:calc(100% + 3px);
    margin-left:-1px;
    margin-top:-2px;
    

    // margin-bottom: 8.5vw;

    .tab {
      width:50%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-left: 1px solid #0E0E0E;
      border-right: 1px solid #0E0E0E;
      border-bottom: 1px solid #0E0E0E;
      border-top:1px solid #0E0E0E;
      border-top-right-radius: 16px;
      border-top-left-radius: 16px;
      margin-left:-1px;
      cursor: pointer;
      
      .tab-title {
        font-size: 1.2vw; 
        font-weight: 400;
        margin:0;
        padding:0;
        margin-left:1.8vw;
        margin-top:16px;
        margin-bottom:16px;
        transition: all .3 sec ease-in-out;

        @media only screen and (max-width: 1023px) {
          font-size: 1.65vw; 
        }
      } 

      .tab-number {
        font-size: 1vw;
        font-weight: 400;
        border: 1px solid #0E0E0E;
        border-radius: 24px;
        height: 1.7vw;
        width: 2.8vw;
        padding-right:.15vw;
        margin-right:1.8vw;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top:16px;
        margin-bottom:16px;
        transition: all .3 sec ease-in-out;

        @media only screen and (max-width: 1023px) {
          font-size: 1.65vw; 
          height: 1.9vw;
          width: 3.2vw;
          padding-right:.2vw;
          margin-right:1.8vw;
        }
      }


      &.active {
        border-bottom: none;

        .tab-number{
          border:none;
        }
      }
    }

    
    
  }

  .tabPanels {
    display: flex;
    width: 100%;
    
    .tabPanel {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width:100%;
      position: absolute;
      top:48px;
      height: calc(100% - 96px);
      pointer-events: none;
      

      h1 {
        font-size: 6.6vw;
        font-weight: 400;
        line-height: 104%;
        margin-left:48px;
        margin-right:48px;
        text-indent: 5vw;
        z-index:1;

        @media only screen and (max-width: 1280px) {
          font-size:5.9vw; 
          line-height: 100%;
          margin-left:4.2vw;
          margin-right:4.2vw;
          
        }

        @media only screen and (max-width: 1023px) {
          font-size:7.2vw; 
        }
      }
      img {
        position: absolute;
        z-index:0;
        width:90%;
        height: 65%;
        object-fit: contain;
      }
    }
  }

 
  

      
    
}




/* Custom compoentn MOBILE Version */


.tabbed-block-mobile{
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:100%;
  border:none;
  border-radius: 16px;
  position: relative;
  padding:1px;
  margin-bottom: 16px;
  border: 1px solid #0E0E0E;

  @media only screen and (max-width: 767px) {
    display: flex;
  }
  
  .tabs{
    margin:0;
    padding:0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:100%;
    height: auto;

    .tab {
      width:100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      height: 17vw;
      overflow:hidden;
      border-bottom: 1px solid #0E0E0E;

      &:last-of-type {
        border:none;
      }
      
      .tab-top-row{

        width:calc(100% - 8.4vw);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .tab-title {
          font-size: 5.3vw; 
          font-weight: 400;
          margin:0;
          padding:0;
          margin-left:1.8vw;
          transition: all .3 sec ease-in-out;
        } 

        .tab-number {
          // font-size: 5.3vw;
          font-weight: 400;
          border: 1px solid #0E0E0E;
          border-radius: 24px;
          height: 5.8vw;
          width:10vw;
          padding-right:.15vw;
          margin-right:1.8vw;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top:16px;
          margin-bottom:16px;
          transition: all .3 sec ease-in-out;
  
         
        }

        

      }
      
      .tabPanel {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width:100%;
        height: auto;
        pointer-events: none;
        position: relative;
  
        h1 {
          font-size: 12.8vw;
          font-weight: 400;
          line-height: 108%;
          margin-left:4.2vw;
          margin-right:4.2vw;
          text-indent: 5vw;
          z-index:1;
        }
        img {
          position: absolute;
          z-index:0;
          width:90%;
          height: 65%;
          object-fit: contain;
        }
      }
      
      &.active {
        .tab-number {
          border:1px solid transparent;
        }
      }

    }

    
    
  }

  
  

 
  

      
    
}