.capabilities-block{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width:100%;
  border:none;
  border-radius: 16px;
  position: relative;
  margin-bottom: 16px;
  gap:16px;
  height:auto;
  overflow-y: hidden;
  overflow-x: auto;
  flex-shrink: 0;
  box-sizing: border-box; 



  .tag-block {
    width:calc(33%);
    border-radius: 16px;
    border: 1px solid #0E0E0E;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    padding-top:2.7vw;
    padding-bottom:2.22vw;
    flex-shrink: 1;
    box-sizing: border-box;

    @media only screen and (max-width: 767px) {
      width:88%;
      padding-top:4.2vw;
      flex-shrink: 0;
    }
    
    .title-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width:calc(100% - 4.2vw);
      margin-left:2.1vw;
      margin-bottom:1vw;

      @media only screen and (max-width: 767px) {
        width:calc(100% - 8.4vw);
        margin-left:4.2vw;
        margin-bottom:4.2vw;
      }

      h1 {
        padding:0;
        margin:0;
        font-size:1.94vw;
        font-weight: 400;

        @media only screen and (max-width: 767px) {
          font-size:7.6vw;
          
        }
      }

      .title-number{
        padding:0;
        margin:0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 1.66vw;
        border: 1px solid #0E0E0E;
        width:2.67vw;
        padding-right:.1vw;
        font-size: .97vw;

        @media only screen and (max-width: 767px) {
          font-size: 3.7vw;
          width:10.6vw;
          height: 6vw;
          border-radius: 6.4vw;
        }
        
      }

    }

    .tag-list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width:calc(100% - 4.2vw);
      margin-left:2.1vw;

      @media only screen and (max-width: 767px) {
        margin-left:4.2vw;
        width:calc(100% - 8.4vw);
      }

      .tag {
        border-top: 1px solid #83807C;
        font-size: 1.3vw;
        line-height: 2.7vw;
        font-weight: 400;
        // color:#83807C;
        width:100%;

        @media only screen and (max-width: 767px) {
          font-size:5.33vw;
          line-height: 10.666vw;
        }

        &.first {
          border-top:none;
        }
      }
      
    }
    
  }
  
}