.big-divider-volta-split{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width:100%;
  border:none;
  position: relative;
  margin-bottom: 16px;
  height: 10vw;
  gap:16px;
  box-sizing: border-box; 

  &.KlarheitKurrent {
    @media only screen and (max-width: 767px) {
      height: unset;
    }
  }

  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }

  .cell {
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    height: 100%;
    border-radius: 16px;

    @media only screen and (max-width: 767px) {
      border-radius: 3.2vw;
    }

    img {
      height: 60%;
      width: 75%;
      object-fit: contain;
    }

  }

  &.split-60 {
    .cell-1 {
      width: 60%;
      @media only screen and (max-width: 767px) {
        width:100%;
      }
    }
    .cell-2 {
      width: 40%;
      @media only screen and (max-width: 767px) {
        width:100%;
      }
    }
  }

  &.split-25-50-25 {
    .cell-1 {
      width: 25%;
      @media only screen and (max-width: 767px) {
        width:100%;
      }
    }
    .cell-2 {
      width: 50%;
      @media only screen and (max-width: 767px) {
        width:100%;
      }
    }
    .cell-3 {
      width: 25%;
      @media only screen and (max-width: 767px) {
        width:100%;
      }
    }
  }

  @media only screen and (max-width:1023px) {
    height: auto;
    border-radius:2vw;
  }

  @media only screen and (max-width: 767px) {
    height: auto;
    border-radius: 3.2vw;
  }


  .title {
    padding:0;
    margin: 0;
    display: flex;
    font-style: normal;
    font-weight: 400;
    font-size: 6.944vw;
    line-height: 100%;
    letter-spacing: -0.01em;
    font-feature-settings: 'ss02' on;
    // margin-top:4.2vw;
    // margin-bottom:4.2vw;
    text-align: center;
    justify-content: center;

    &.KlarheitKurrent
    {
      font-family: "KlarheitKurrent";
      letter-spacing: -0.04em;
      font-weight:500;
      font-feature-settings: 'ss02' on;
      font-size:7.4vw;
      line-height: 100%;
      width:100%;
      
      @media only screen and (max-width: 767px) {
        font-size: 16.75vw;
      }
    }

    @media only screen and (max-width: 767px) {
      font-size: 8.5vw;
      line-height: 100%;
      text-align: center;
      width:calc(100%);
      margin-top:4.2vw;
      margin-bottom:4.2vw;
    }
  }

  .text {
    font-size: 1vw;
    line-height: 100%;
    font-feature-settings: 'ss02' on;

    @media only screen and (max-width: 767px) {
      &.hide-small-text{
        display:none;
      }
    }
    &.left {
      margin-left:48px;
      width:7.4vw;
      @media only screen and (max-width: 1023px) {
        margin-left:4.2vw;
        font-size: 2.4vw;
        width:16vw;
      }
    }
    &.right {
      margin-right:48px;
      width:7.4vw;
      text-align: right;
      @media only screen and (max-width: 1023px) {
        margin-right:4.2vw;
        font-size: 2.4vw;
        width:16vw;
      }
    }

  }

 
      
    
}