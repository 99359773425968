.volta-media-grid{
  display:flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width:100%;
  border:none;
  border-radius: 1.45vw;
  position: relative;
  margin-bottom: 16px;
  box-sizing: border-box; 

  &.grid-3 {
    gap:16px;
    .block {
      margin:0;
      padding:0;
      width: calc(33.333% - 48px);
      aspect-ratio: 1;
      display: flex;
   
      
      flex-grow:1;
      .media {
        width: 100%;
        height: 100%;
        margin:0;
        padding:0;
        
      }
    }
  }

  &.split-30 {
    gap:16px;
    flex-direction: row;
    flex-wrap: nowrap;
    .block {
      margin:0;
      padding:0;
      // width: calc(67% - 8px);
      
      // aspect-ratio: 1;
      display: flex;
      
      &.cell-0 {
      //  flex-grow: 1;
      //  max-width: 100%;
       width: calc(100% - 66.9% - 8px);
      }

      &.cell-1 {
       width: calc(66.9% - 8px);
      //  flex-grow: 0;
    
      }
      
      
      .media {
        width: 100%;
        max-width: 100%;
        margin:0;
        padding:0;
        
      }
    }
  }

  &.split-50 {
    gap:16px;
    .block {
      margin:0;
      padding:0;
      width: calc(50% - 8px);
      display: flex;
      
     
      .media {
        width: 100%;
        height: 100%;
        margin:0;
        padding:0;
        
      }
    }
  }
  
  

  @media only screen and (max-width: 767px) {
    //  border-radius: 4vw;
     flex-direction: column;
     &.split-30 {
      flex-direction: row;
      flex-wrap:wrap;
      .block {

        width:100%;
        border-radius: 16px;
        overflow:hidden;
        &.cell-0 {
          width:100%;
        }  
        &.cell-1 {
          width:100%;
        }  
     }
    }

    &.grid-3 { 
      .block {
        width:100%;
     }
    }
     
    &.split-50 {
      .block {
        width:100%;
      }
    }

  }


      
    
}