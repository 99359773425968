.media-block{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:100%;
  // height: 640px;
  // aspect-ratio: 1190/720;
  // flex-shrink: 1; 
  // flex-grow: 1;
  border:none;
  border-radius: 16px;
  position: relative;
  margin-bottom: 16px;
  // border: 1px solid #0E0E0E;
  position: relative;
  box-sizing: border-box; 

  @media only screen and (max-width: 767px) {
    &.hide-on-mobile {
      display: none;
    }
  }

  .extra-image {
    position: absolute;
    width:5.4vw;
    bottom:3vw;

    @media only screen and (max-width: 767px) {
      width:8.4vw;
      bottom:10vw;
    }

    &.center {
      bottom:unset;
      width:18vw;
      @media only screen and (max-width: 767px) {
        width:75vw;
      }
    }

   
  }




  &.full-bleed-plus{      
    overflow:hidden;
    @media only screen and (max-width: 767px) {
      aspect-ratio: unset;
      
    }
  }


  &.full-bleed{
    flex-shrink: unset; 

  }

  &.tight {
    aspect-ratio: unset;
  }

  &.round-overflow-hidden{
    overflow:hidden;
    border-radius: 16px;
  }

  @media only screen and (max-width: 767px) {
    &.mobileRotate90 {
      height:100vw;
      flex-grow: 1;
      flex-shrink: 0;
    }
  }

  .lf-player-container {
    // width:inherit;
    padding:0;
    margin:0;
  }
  
  h1 {
    margin:0;
    padding:0;
    font-size:8.3vw;
    font-weight: 400;
    margin-bottom:-4vw;
  }

  .media {
    width:82%;
    height: auto;
    margin-top:1.666vw;
    border-radius: 16px;

    &.mobile-hide{
      @media only screen and (max-width: 767px) {
        display: none;
      }
    }
    

    &.tight {
      margin-top:0;
    }

    &.larger {
      width:91%;
      margin-top:0px;
    }
    &.full {
      width:calc(100% - 96px);
      padding:0;
      margin: 0;
      margin-left:48px;
      margin-right:48px;
      border-radius: 0;
      @media only screen and (max-width: 767px) {
        width: calc(100% - 8.4vw);
      }
    }
    &.full-bleed {
      width:calc(100%);
      object-fit: cover;
      padding:0;
      margin: 0;

      &.fit {
        video {
          border-radius: 16px;
        }
      }
    }

    &.full-bleed-contain {
      width:calc(100%);
      padding:0;
      margin: 0;
      
    }

    &.full-bleed-plus {
      width:calc(104%);
      object-fit: cover;
      padding:0;
      margin: 0;

    }

    &.topPad {
      
      margin-top:4.44vw;
    }

    &.bottomPad {
      margin-bottom:4.44vw;
    }

    &.topPadSmall {
      
      margin-top:2.2vw;
    }

    &.bottomPadSmall {
      margin-bottom:2.2vw;
    }

    &.topPadLarger {
      
      margin-top:9vw;
    }

    &.bottomPadLarger {
      margin-bottom:11vw;
    }
    &.offset-top{
      margin-top:-11vw;
    }

    &.offset-top-big{
      margin-top:-30vw;
    }
    &.inset-80 {
      width:80vw;
    }

    &.inset-50 {
      width:52vw;
      @media only screen and (max-width: 767px) {
        width:90vw;
        margin-top:4.2vw;
        margin-bottom:4.2vw;
      }

    }

   
    &.video {
      width:100%;
      padding:0;
      margin:0;
      
      box-sizing: border-box;
      // .react-player {
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      // }
     
    }
    &.over-sized-video  {
      width:170%;
      position: relative;
      top:-38%;
      margin-bottom:-40%;
    }



    
    @media only screen and (max-width: 767px) {
      &.mobileRotate90 {
        transform:rotate(90deg);
        width:96vw;
      }
      &.paddingBottomMobile {
        margin-bottom:8.4vw;
      }

      &.over-sized-video  {
        width:260vw;
        height:140vw;
        position: relative;
        top:0;
        margin-bottom:0;
      }
  
    }
    
  }


  

  .media-mobile {
    width:82%;
    height: auto;
    margin-top:1.666vw;
    display: none;

    @media only screen and (max-width: 767px) {
      display: block;
      
    }

    &.tight {

    }

    &.larger {
      width:91%;
      margin-top:0px;
    }
    &.full {
      width:calc(100% - 96px);
      padding:0;
      margin: 0;
      margin-left:48px;
      margin-right:48px;
    }
    &.full-bleed {
      width:calc(100%);
      object-fit: cover;
      padding:0;
      margin: 0;
      @media only screen and (max-width: 767px) {
        border-radius:16px;
      }
      
    }

    &.full-bleed-contain {
      width:calc(100%);
      padding:0;
      margin: 0;
      
    }
    &.topPad {
      
      margin-top:4.44vw;
    }

    &.bottomPad {
      margin-bottom:4.44vw;
    }
    &.topPadLarger {
      
      margin-top:9vw;
    }

    &.bottomPadLarger {
      margin-bottom:9vw;
    }
    &.offset-top{
      margin-top:-11vw;
    }

    &.offset-top-big{
      margin-top:-30vw;
    }
    &.inset-80 {
      width:80vw;
    }

    


    &.full-bleed-plus {
      padding:0;
      margin: 0;
      width:calc(100%);
      // height: 150vw;
      flex-shrink: 0;

      // object-fit: cover;
      
      
    }

   
    
  }

  


      
    
}