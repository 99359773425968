.meta-ai-media-block{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:100%;
  border:none;
  border-radius: 16px;
  position: relative;
  margin-bottom: 16px;
  position: relative;
  box-sizing: border-box; 
  aspect-ratio: 1190/670;
  
  &.full{      
    overflow:hidden;
    
  }

  .media {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .title {
    position: absolute;
    font-family: "Optimistic Text";
    font-size: 5.555vw;
    font-weight: 400;
    line-height: 100%;
    letter-spacing:-.1666vw;
    left:48px;
    width:52vw;
  }

  .sub-title {
    position: absolute;
    font-family: "Optimistic Text";
    font-size: 1.25vw;
    font-weight: 700;
    letter-spacing:.14vw;
    left:48px;
    top:96px;
    width:50vw;
  }


 
  @media only screen and (max-width: 767px) {
    .title {
      position: absolute;
      font-family: "Optimistic Text";
      font-size: 6.7vw;
      font-weight: 400;
      line-height: 100%;
      letter-spacing:-.2vw;
      left:4.8vw;
      width:52vw;
    }
  
    .sub-title {
      position: absolute;
      font-family: "Optimistic Text";
      font-size: 1.25vw;
      font-weight: 700;
      letter-spacing:.14vw;
      left:4.8vw;
      top:4.8vw;
      width:50vw;
    }
  }


      
    
}