.big-divider{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width:100%;
  border:none;
  border-radius: 16px;
  position: relative;
  margin-bottom: 16px;
  height: 10vw;
  box-sizing: border-box; 

  &.KlarheitKurrent {
    height: 12vw;
    @media only screen and (max-width: 767px) {
      height: 18vw;
    }
  }

  &.SFPro {
    height: 12vw;
    @media only screen and (max-width: 767px) {
      height: 18vw;
    }
  }

  &.OptimisticText {
    height: 11vw;
    @media only screen and (max-width: 767px) {
      height: 28vw;
    }
  }

  &.HTQ {
    height: 11vw;
    @media only screen and (max-width: 767px) {
      height: 28vw;
    }
  }

  @media only screen and (max-width:1023px) {
    height: auto;
    border-radius:2vw;
  }

  @media only screen and (max-width: 767px) {
    height: auto;
    border-radius: 3.2vw;
  }

 

  .title {
    padding:0;
    margin: 0;
    display: flex;
    font-style: normal;
    font-weight: 400;
    font-size: 6.944vw;
    line-height: 100%;
    letter-spacing: -0.01em;
    font-feature-settings: 'ss02' on;
    margin-top:4.2vw;
    margin-bottom:4.2vw;
    text-align: center;
    justify-content: center;

    &.KlarheitKurrent
    {
      font-family: "KlarheitKurrent";
      letter-spacing: -0.04em;
      font-weight:500;
      font-feature-settings: 'ss02' on;
      font-size:7.4vw;
      line-height: 100%;
      width:100%;
    }

    &.SFPro
    {
      font-family: "SF Pro";
      letter-spacing: -0.04em;
      font-weight:400;
      font-feature-settings: 'ss02' on;
      font-size:6.6vw;
      line-height: 100%;
      width:100%;
      margin-left:-16vw;
      margin-right: -16vw;

      @media only screen and (max-width: 767px) {
        font-size: 8.4vw;
        letter-spacing: -.186vw;
        margin-left:-16vw;
        margin-right: -16vw;
      }
    }

    &.OptimisticText
    {
      font-family: "Optimistic Text";
      letter-spacing: -0.04em;
      font-weight:400;
      font-feature-settings: 'ss02' on;
      font-size:6.6vw;
      line-height: 100%;
      width:100%;
      margin-left:-16vw;
      margin-right: -16vw;
      padding-bottom: 0.2vw;

      @media only screen and (max-width: 767px) {
        font-size: 8.4vw;
        letter-spacing: -.186vw;
        margin-left:-16vw;
        margin-right: -16vw;
      }
    }

    &.HTQ
    {
      font-family: "HTQ-Waldenburg-FettSchmal";
      letter-spacing: -.1388vw;
      font-weight:700;
      font-feature-settings: 'ss02' on;
      font-size:6.6vw;
      line-height: 100%;
      width:100%;
      margin-left:-16vw;
      margin-right: -16vw;
      padding-bottom: 0.2vw;

      @media only screen and (max-width: 767px) {
        font-size: 8.4vw;
        letter-spacing: -.186vw;
        margin-left:-16vw;
        margin-right: -16vw;
      }
    }

    @media only screen and (max-width: 767px) {
      font-size: 8.5vw;
      line-height: 100%;
      text-align: center;
      width:calc(100%);
      margin-top:4.2vw;
      margin-bottom:4.2vw;
    }
  }

  .text {
    font-size: 1vw;
    line-height: 100%;
    font-feature-settings: 'ss02' on;

    @media only screen and (max-width: 767px) {
      &.hide-small-text{
        display:none;
      }
    }
    &.left {
      margin-left:48px;
      width:7.4vw;
      @media only screen and (max-width: 1023px) {
        margin-left:4.2vw;
        font-size: 2.4vw;
        width:16vw;
      }
    }
    &.right {
      margin-right:48px;
      width:7.4vw;
      text-align: right;
      @media only screen and (max-width: 1023px) {
        margin-right:4.2vw;
        font-size: 2.4vw;
        width:16vw;
      }
    }

  }

 
      
    
}