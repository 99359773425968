.case-study-how-to-quit{
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  flex-shrink: 0;
  width:100%;
  // overflow:hidden;
  height:0;
  // transition:1s height ease-in-out;
}