.careers-list-block{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: start;
  width:100%;
  // height: 640px;
  border:none;
  position: relative;
  margin-bottom: 16px;
  gap:8px;
  // flex-shrink:1;
  // flex-grow:0;
  box-sizing: border-box; 

  .career-list-item {
    // width:100%;
    margin:0;
    padding:0;
    
    font-size: 9.7vw;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items:start;
    width: 100%;
    gap:8px;
   
    // flex-shrink:1;
  
    
    .position {
      display: flex;
      flex-direction: column;
      justify-content:flex-start;
      align-items: center;
      border: 1px solid #0E0E0E;
      border-radius: 2.8vw;
      // width:70vw;
      width:100%;
      max-height: 5.6vw;
      height: auto;
      overflow:hidden;
      cursor: pointer;

      &:hover {
        background: #FF003D;
      }

      transition: all 0.3s cubic-bezier(0.85, 0, 0.15, 1);

      &.open {
        max-height: 120vw;
        &:hover {
          background: unset;
        }
      }

      @media only screen and (max-width: 767px) {
        height: auto;
        max-height: 8.6vw;
        border-radius: 10vw;
        // &.open {
        //   // height: auto;
        //   max-height: 100vw;
        // }
      }

      &.no-positions{
        width:100%;
      }

      .position-title {
        margin-top:1.666vw;
        display: flex;
        gap:16px;
        flex-direction: row;
        justify-content:center;
        align-items: center;
        .title{
          padding:0;
          margin: 0;
          font-size:2vw;
          font-weight: 400;

          @media only screen and (max-width: 767px) {
            font-size:3.4vw;

          }
        }
        .category{
          padding:0;
          margin: 0;
          font-size: 1.2vw;
          font-weight: 400;
          color:#83807C;
          display: none;
        }
      } 

      .text-1 {
        // color: #83807C;
        color:#0E0E0E;
        font-size:2vw;
        line-height: 2.2vw;
        margin-left:48px;
        margin-right:48px;

        @media only screen and (max-width: 1023px) {
          font-size:2.6vw;
          line-height: 2.8vw;
          margin-left:4.2vw;
          margin-right:4.2vw;
        }

        @media only screen and (max-width: 767px) {
          font-size:3.6vw;
          line-height: 3.8vw;
        }
      }

      .text-2 {
        font-size:1.4vw;
        line-height: 1.6vw;
        margin-left:48px;
        margin-right:48px;
        color:#0E0E0E;
        gap:48px;
        display: flex;
        flex-direction: row;
        justify-content:space-between;
        align-items: flex-start;

        p {
          width:50%;
        }

        @media only screen and (max-width: 1023px) {
          font-size:1.8vw;
          line-height: 2.0vw;
          margin-left:4.2vw;
          margin-right:4.2vw;
          gap:4.2vw;
        }

        @media only screen and (max-width: 767px) {
          font-size:3.2vw;
          line-height: 3.4vw;
        }
      }

      .bottom-row{
        display: flex;
        flex-direction: row;
        justify-content:start;
        align-items: center;
        color:#0E0E0E;
        font-size:1.1vw;
        margin-left:48px;
        margin-right:48px;
        width:calc(100% - 96px);
        padding-top:48px;
        margin-top:24px;
        margin-bottom:48px;
        border-top:1px solid #0E0E0E;
        gap:48px;

        @media only screen and (max-width: 1023px) {
          width:calc(100% - 8.4vw);
          margin-left:4.2vw;
          margin-right:4.2vw;
          padding-top:2.1vw;
          padding-bottom:2.1vw;
          margin-bottom:4.2vw;
          gap:4.2vw;
          font-size:1.8vw;
        }

        @media only screen and (max-width: 767px) {
          
          font-size:2.8vw;
        }

        a {
          color:#0E0E0E;
          padding:0; margin:0;
        }

        .bottom-row-location{
          display: flex;
          flex-direction: row;
          justify-content:start;
          align-items: center;
          gap:16px;
          width:50%;

          @media only screen and (max-width: 767px) {
            flex-direction: column;
            justify-content:flex-start;
            align-items: flex-start;
            gap:4px;

          }
        }

        .bottom-row-timezone{
          display: flex;
          flex-direction: row;
          justify-content:start;
          align-items: center;
          gap:16px;
          width:50%;

          @media only screen and (max-width: 767px) {
            flex-direction: column;
            justify-content:flex-start;
            align-items: flex-start;
            gap:4px;

          }
         
        }
      }


    }

    
    
    .career-button {
      background: #FF003D;
      border-radius: 2.8vw;
      display: flex;
      justify-content: center;
      align-items: center;
      color:#0E0E0E;
      padding:0;
      margin: 0;
      font-size: 1.8vw;
      font-weight: 400;
      height: 5.6vw;
      flex-grow:1;
      border:none;
      cursor: pointer;
    }
    
  }

  


      
    
}