.video-player-block{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:100%;
  border:none;
  border-radius: 16px;
  position: relative;
  margin-bottom: 16px;
  box-sizing: border-box; 
  background: transparent;
  border: none;

  overflow:hidden;

  .image {
    position: absolute;
    z-index: 1;
    opacity:1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    pointer-events:all;
    border:0;
    background:transparent;
    display: none;
  }

  .play-button {
    position: absolute;
    z-index: 2;
    opacity:.7;
    width:10vw;
    cursor: pointer;
    transition: .3s ease-out;
    &:hover {
      opacity: 1;
    }

    @media only screen and (max-width: 767px) {
      width:24vw;
    }
    display: none;
  }

  .media {
    z-index: 0;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
    background: transparent;
    border: none;
    .react-player {
      position: absolute;
      top: 0;
      left: 0;
    }
   
  } 
    
}