.mobile-navigation{
  display:none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  left:0;
  bottom:0;
  width:100vw;

  background:#F4E9E1;
  border-top:#0E0E0E 1px solid;
  z-index: 1200;


  @media only screen and (max-width: 1023px) {
    display:flex;
  }



  nav {
    display: block;
    margin:0;
    padding:0;
    margin-top:16px;
    margin-bottom:16px;
    width:100vw;
    
    overflow-x:auto;
    overflow-y:hidden;
    flex-shrink: 0;

    &::-webkit-scrollbar{
      width: 0;
      width:none;
    }
    .nav-items{
      margin:0;
      padding:0;
      flex-shrink:0;
      margin-left:4.2vw;
      margin-right:4.2vw;
      gap:12px;
      display: inline-flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items:center;
      // width:auto;
      height: 56px;
      // flex-shrink: 0;
      

      .nav-item {
        transition: width .5s ease-out;
        border: 1px solid transparent;
        margin:0;
        padding: 0; 
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        border-radius: 16px;
        height:100%;
        flex-shrink:0;
        flex-grow:0;

        pointer-events: all;
        cursor: pointer;

        .nav-item-number {
          margin-left:8px;
          margin-top:8px;
          font-size:8px;
        }
        .nav-item-text {
          margin-bottom:8px;
          margin-left:8px;
          font-size: 12px;
        }

        // .nav-dot{
        //   position: absolute;
        //   right:8px;
        //   border-radius: 50%;
        //   margin:0;
        //   padding:0;
        //   width: 6px;
        //   height: 6px;
        //   opacity: 0;
        //   top:calc((100% - 32px) * 0.5 + 16px);
        // }
        // .nav-dot-0{ display: none;}

        &:hover {
          // background: none;
          // border: 1px solid #0E0E0E;
          // color:#0E0E0E;
        }

      }
    
    }
  }
  
  // .mobile-nav-footer {
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: space-between;
  //   margin-left:4.2vw;
  //   margin-right:4.2vw;
  //   height:24px;
  //   color:#0E0E0E;
  //   font-size: 12px;
  //   font-weight: 300;
  //   width:calc(100% - 8.4vw);
  //   h4 {
  //     padding: 0;
  //     margin: 0;
  //     font-weight: 400;
  //   }
  //   a {
  //     color:#0E0E0E;
  //     font-size: 12px;
  //     font-weight: 400;
  //   }
  // }
  
}


