.work{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width:100%;

  .box {
    justify-content: flex-start;
    
    &.section-start {
      background: black;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      h1 {
        color:white;
        text-align: left;
        font-weight: 300;
        font-size: 124px;
        line-height: 148px;
        letter-spacing: -0.01em;
        margin:0;
        padding:0;
        margin-left:24px;
      }
    }
  }


  
}