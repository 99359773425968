.careers-title-block{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: start;
  width:100%;
  // height: 640px;
  aspect-ratio: 1190/736;
  border:none;
  position: relative;
  margin-bottom: 16px;
  box-sizing: border-box; 
 
  // flex-shrink:1;
  // flex-grow:0;

  .title {
    // width:100%;
    margin:0;
    padding:0;
    border-radius: 13vw;
    border: 1px solid #0E0E0E;
    font-size: 9.7vw;
    font-weight: 400;
    display: flex;
    justify-content: center;
    padding-left:4vw;
    padding-right:4vw;
    // flex-shrink:1;
    flex-grow:1;
    
    
  }

  

  .section-labels{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width:100%;
    // margin-top:24px;
    // margin-bottom:48px;
    position: absolute;
    bottom:48px;

    @media only screen and (min-width: 1920px) {
      position: absolute;
      bottom:48px;
      margin-bottom:0px;
    }


    .section-title {
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      letter-spacing: -0.01em;
      font-feature-settings: 'ss02' on;
      padding:0;
      margin:0;
      margin-left:48px;
    }

    .section-number {
      color:#0E0E0E;
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      text-align: right;
      font-feature-settings: 'ss02' on;
      margin:0;
      margin-right:48px;
      letter-spacing: -0.01em;
    }

  }

  

      
    
}