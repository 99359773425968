
  .talent-people-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width:100%;
    position: relative;
    margin-bottom: 16px;
    gap:24px;
    overflow-x: auto;
    flex-shrink: 0;

    .people-card {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      border-radius: 16px;
      border: 1px solid #0E0E0E;
      box-sizing: border-box; 
      width:calc(33% - 24px);
      aspect-ratio: 380/600;
      position: relative;
      flex-shrink:0;
      overflow:hidden;

      h3 {
        margin:0;
        padding:0;
        font-size:1.6vw;
        font-weight: 400;
        position: absolute;
        top:24px;
        z-index: 1;
      }
      img {
        position: absolute;
        object-fit: cover;
        margin:0;
        padding:0;
        width:100%;
        height: 100%;
        z-index: 0;
      }

      h4 {
        margin:0;
        padding:0;
        position: absolute;
        bottom:32px;
        width:50%;
        font-size:3.2vw;
        line-height: 3.2vw;
        font-weight: 400;
        left:48px;
      }
    }

  }       
