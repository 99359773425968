.big-divider-volta-advanced{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width:100%;
  border:none;
  border-radius: 16px;
  position: relative;
  margin-bottom: 16px;
  height: 22vw;
  box-sizing: border-box; 

  @media only screen and (max-width: 767px) {
    border-radius: 3.2vw;
    height: auto;
    aspect-ratio: 375/84;
    
  }

  .titles{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap:4vw;
    margin-left:4.2vw;
  }

  .sub-title {
    margin:0;
    padding:0;
    font-style: normal;
    font-weight: 400;
    font-size: 2.8vw;
    letter-spacing: -0.02em;
    font-feature-settings: 'ss02' on;
    font-family: "KlarheitKurrent";
    
    @media only screen and (max-width: 767px) {
      font-size:3vw;
      line-height: 100%;
      margin-top:4.2vw;
      margin-bottom:4.2vw;
      text-align: center;

    }
  }


  .title {
    margin:0;
    padding:0;
    font-style: normal;
    font-weight: 400;
    font-size: 5.5vw;
    letter-spacing: -0.02em;
    font-feature-settings: 'ss02' on;
    font-family: "KlarheitKurrent";
    
    @media only screen and (max-width: 767px) {
      font-size:6vw;
      font-weight: 500;
      line-height: 100%;
      margin-top:0;
      margin-bottom:4.2vw;
      text-align: center;
    }
  }

 .icon {
  height: 14vw;
  margin:0;
  padding:0;
  margin-left:4.2vw;
  margin-right:4.2vw;

    @media only screen and (max-width: 767px) {
      height: 18vw;
      margin-right:4.2vw;
    }
  
 }
 .small-icon {
  position: absolute;
  height: 3.8vw;
  margin:0;
  padding:0;
  left:57vw;
  top:4vw;
  
  @media only screen and (max-width: 767px) {
    height: 4.8vw;
    margin:0;
    padding:0;
    right:25.5vw;
    top:4vw;
  }
 }
 


 &.right-align {
  .icon {
    
    height: 14vw;
    margin-left:2.4vw;

    @media only screen and (max-width: 767px) {
      height: 18vw;
      position: relative;
      margin-left:2.4vw;
      margin-right:12vw;
    }

   }

   .small-icon {
    left:unset;
    right:51vw;
    top:4vw;
   }


  .titles{
    align-items: flex-end;
    margin-right: 2.4vw;
  }
  .sub-title {
    @media only screen and (max-width: 767px) {
      font-size:3vw;
      line-height: 100%;
      margin-top:4.2vw;
      margin-bottom:4.2vw;
      text-align: center;
    }

    
  }


  .title {
    @media only screen and (max-width: 767px) {
      font-size:6vw;
      font-weight: 500;
      line-height: 100%;
      margin-top:0;
      margin-bottom:4.2vw;
      text-align: center;
    }
  }
 }
      
    
}