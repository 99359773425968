.color-divider{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width:100%;
  border:none;
  position: relative;
  margin-bottom: 16px;
  height: 5vw;
  box-sizing: border-box; 


  .block {
    width: 100%;
    height: 100%;
    gap:0;
    padding:0; 
    margin:0;

    &:first-child {
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
    }

    &:last-child {
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }

  @media only screen and (max-width: 767px) {
     border-radius: 4vw;
     height: 36vw;

     .block {
        &:first-child {
          border-top-left-radius: 3.2vw;
          border-bottom-left-radius: 3.2vw;
        }
    
        &:last-child {
          border-top-right-radius: 3.2vw;
          border-bottom-right-radius: 3.2vw;
        }
     }
  }


      
    
}