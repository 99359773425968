.media-title-text-block{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:100%;
  // height: 640px;
  // aspect-ratio: 1190/736;
  // flex-shrink: 0; 
  border:none;
  border-radius: 16px;
  position: relative;
  margin-bottom: 16px;
  border: 1px solid #0E0E0E;
  box-sizing: border-box; 

  &.paddingBottom {
    padding-bottom:64px;
  }

  &.paddingBottomBig {
    padding-bottom:12vw;
  }
  
  .titles{
    margin:0;
    padding:0;
    margin-top:2.44vw;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    margin-bottom:8.5vw;  
    @media only screen and (max-width: 767px) {
      margin-top:4.8vw;
    }
  }

  .title {
    font-family: 'RightGrotesk';
    letter-spacing: -0.01em;
    font-feature-settings: 'ss02' on;
    font-size: 13.8vw;
    line-height: 15.8vw;
    vertical-align: top;
    font-weight: 700;
    margin:0;
    padding:0;
    text-align-last:justify;
    width:calc(100% - 96px);
    // align-items: center;
    text-align: justify;
    background-image: linear-gradient(transparent calc(100% - 1px),#0E0E0E);

    @media only screen and (max-width: 1023px) {
      width:calc(100% - 8.4vw);
    }

    @media only screen and (max-width: 767px) {
      font-size:16.8vw;
      line-height: 20.5vw;
    }
    
    
    &:last-of-type {
      background-image:none;
    }

    &.center {
      text-align: center;
      text-align-last:unset;
      width:calc(88% - 96px);

      @media only screen and (max-width: 1023px) {
        width:calc(88% - 8.4vw);
      }
    }

    &.right{
      text-align:right;
      text-align-last:unset;
      width:calc(88% - 96px);
      padding-right:12%;

      @media only screen and (max-width: 1023px) {
        width:calc(88% - 8.4vw);
      }
    }

    &.left{
      margin:0;
      text-align:left;
      text-align-last:unset;
      width:calc(100% - 96px);

      @media only screen and (max-width: 1023px) {
        width:calc(100% - 8.4vw);
      }
    }



    &.right2{
      text-align:right;
      text-align-last:unset;
    }

    &.indent{
     text-indent: 25%;
    }

    &.right-mobile {
      @media only screen and (max-width: 767px) {
        width:calc(100% - 8.4vw);
        margin:0;
        text-align:right;
        text-align-last:unset;
      }
    }
    &.left-mobile {
      @media only screen and (max-width: 767px) {
        width:calc(100% - 8.4vw);
        margin:0;
        text-align:left;
        text-align-last:unset;
      }
    }
    &.justify-mobile {
      @media only screen and (max-width: 767px) {
        width:calc(100% - 8.4vw);
        margin:0;
        text-align:right;
        text-align-last:unset;
      }
    }

  }

  .small-text {
    position: absolute;
    width:31vw;
    font-size: 1.66666vw;
    line-height: 1.944vw;
    left:48px;
    top:53vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    text-indent: 25%;

    &.brand {
      top:68vw;
      width:31vw;

      @media only screen and (max-width: 767px) {
        position: relative;
        font-size: 5.33vw;
        line-height: 6vw;
        width: calc(100% - 8.4vw);
        justify-content: flex-start;
        align-items: flex-start;
        left:0vw;
        top:4.2vw;
        text-indent: 12%;
      }
    }


    @media only screen and (max-width: 767px) {
      position: relative;
      font-size: 5.33vw;
      line-height: 6vw;
      width: calc(100% - 8.4vw);
      justify-content: flex-start;
      align-items: flex-start;
      left:0vw;
      top:4.2vw;
      text-indent: 12%;

      &.margin-bottom{
        margin-bottom:8.4vw;
      }
    }
    

  }

  .small-text2 {
    position: absolute;
    width:22vw;
    font-size: 1.66666vw;
    line-height: 1.944vw;
    right:48px;
    top:55vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    text-indent: 25%;

    &.brand {
      top:68vw;
      width:31vw;

      @media only screen and (max-width: 767px) {
        position: relative;
        font-size: 5.33vw;
        line-height: 6vw;
        width: calc(100% - 8.4vw);
        left:0vw;
        right: unset;
        justify-content: flex-start;
        align-items: flex-start;
        top:10vw;
        text-indent: 12%;
        z-index: 1;
      }
    }

    @media only screen and (max-width: 767px) {
 
      position: relative;
      font-size: 5.33vw;
      line-height: 6vw;
      width: calc(100% - 8.4vw);
      left:0vw;
      right: unset;
      justify-content: flex-start;
      align-items: flex-start;
      top:10vw;
      text-indent: 12%;
      z-index: 1;

      &.margin-bottom{
        margin-bottom:16vw;
      }
    }

  }


  .media {
    img {
      &.marginRight10 {
        margin-right:10vw;
      }
      &.marginLeft20 {
        margin-left:36vw;
      }
      &.marginTopNeg20 {
        margin-top:-44vw;
      }
      &.cup {
        width:35vw;
      }
    }
    .lottie {
      position: absolute;
    }
    .invite-friends {
      position: absolute;
      top:-8vw;
      left:-1vw;
      width:54vw;
    }

    @media only screen and (max-width: 767px) {
      display: none;
      &.mobile-media-special {
        display: flex;
        img {
          max-width:60vw;
          left:12vw;
          margin:0;
          padding:0;
        }
      }
    }

    


  }

  // .media-mobile-special {
  //   display: none;
  // }

  .media-mobile {
    display: none;
    @media only screen and (max-width: 767px) {
      display: flex;
      // width:20vw;
      position: relative;
      margin:0;
      padding:0;
      left:0;
      margin-left:calc(-50% + 4.2vw);
      z-index: 0;
     
      &.mobile-media-special {
        display: none;
      }
      

      img {
        max-width:60vw;
        &.marginRight10 {
          margin-right:10vw;
        }
        &.marginLeft20 {
          margin-left:unset;
        }
        &.marginTopNeg20 {
          margin-top:unset;
        }
        &.cup {
          width:60vw;
          top:-16vw;
          margin-bottom:-8.4vw;
          left:20vw;
          position: relative;
        }
      }

      .lottie {
        // position: absolute;
       
      }
      .invite-friends {
        
        top:-45vw;
        left:22vw;
        width:115vw;
        max-width:unset;
        margin-left:0;
        margin-bottom:-55vw;
        position: relative;
      }
      
    }
    
  }

  .effect {
    position: absolute;
  }
  
      
    
}