.contact-people-row{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width:100%;
  position: relative;
  margin-bottom: 16px;
  gap:16px;
  height:auto;
  overflow-y: hidden;
  overflow-x: auto;
  flex-shrink: 0;
  box-sizing: border-box; 

  .people-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 16px;
    border: 1px solid #0E0E0E;
    position: relative;
    width:calc(33.33%);
    box-sizing: border-box; 
    flex-shrink: 1;
    overflow: hidden;
    box-sizing: border-box;
    //transform: translateZ(0);
    
    @media only screen and (max-width: 767px) {
      width:calc(75%);
      flex-shrink: 0;
    }


    h3 {
      margin:0;
      padding:0;
      font-size:2.8vw;
      line-height: 3vw;
      font-weight: 400;
      top:32px;
      position: absolute;
      width:80%;
      z-index: 1;

      @media only screen and (max-width: 1023px) {

        top:3.2vw;
      }

      @media only screen and (max-width: 767px) {
        font-size: 8.5vw;
        line-height: 8.5vw;
        top:4.8vw;
      }


    }

    .media {
      margin:0;
      padding:0;
      width:100%;
      margin-top:-4vw;
      margin-bottom:0vw;
      z-index:0;
      transform: translateZ(0);


      @media only screen and (max-width: 767px) {
        margin-top:-10vw;
      }

      &.video {
        video {
          width:100%;
          height:100%;
          object-fit: contain;
          border:0;
          background:none;
          transform: translateZ(0);
        }
      }
      
    }


    img {
      margin:0;
      padding:0;
      width:58%;
      margin-top:8.4vw;
      margin-bottom:2.1vw;

      @media only screen and (max-width: 767px) {
        width:55%;
        margin-top:10.6vw;
        margin-bottom:4.2vw;
      }

    }

    button {
      margin:0;
      padding:0;
      position: absolute;
      bottom:32px;
      font-size:1.4vw;
      font-weight: 400;
      width: 77%;
      height: 3.4vw;
      /* Romance/Neon Yellow */
      background: #FFFF00;
      border-radius: 44px;
      border:none;
      cursor: pointer;
      transition: all .3s ease-in-out;

      @media only screen and (max-width: 767px) {
        font-size:4.2vw;
        height: 8.4vw;
      }
    }

    transition: all .3s ease-in-out;
    cursor: pointer;
      &:hover {
        //background: #FFFF00;
        // color:#FFFF00;

        button{
          background: #0E0E0E;
          color:#FFFF00;
  
        }
      }
  }

      
    
}