.section-divider{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width:100%;
  height: 32px;
  border:none;
  border-radius: 16px;
  position: relative;
  margin-bottom: 16px;
  box-sizing: border-box; 

  
  @media only screen and (max-width: 767px) {
    height: 40px;
    border-radius: 24px;
    flex-shrink:0;
  }

  h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.01em;
    font-feature-settings: 'ss02' on;
    margin-left:48px;

    @media only screen and (max-width: 1023px) {
      margin-left:4.2vw;
      font-size: 10px;
    }

  }

  .section-number {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    text-align: right;
    font-feature-settings: 'ss02' on;
    margin-right:48px;

    @media only screen and (max-width: 1023px) {
      margin-right:4.2vw;
      font-size: 10px;
    }
  }

}