.meta-ai-media-block2{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width:100%;
  border:none;
  border-radius: 16px;
  position: relative;
  margin-bottom: 16px;
  position: relative;
  box-sizing: border-box; 
  aspect-ratio: 1190/670;
  
  &.full{      
    overflow:hidden;
    
  }

  .media {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .title {
    position: absolute;
    font-family: "Moderat";
    font-size: 8.333vw;
    font-weight: 400;
    line-height: 100%;
    letter-spacing:-.08333vw;
    text-transform: uppercase;
    
  }

  .sub-title {
    position: absolute;
    font-family: "Moderat";
    font-size: 1.66vw;
    font-weight: 400;
    letter-spacing:.14vw;
    right:96px;
    top:96px;
    width:30vw;
    text-transform: uppercase;
  }

  .sub-title2 {
    position: absolute;
    font-family: "Moderat";
    font-size: 1.66vw;
    font-weight: 400;
    letter-spacing:.14vw;
    left:96px;
    bottom:6.4vw;
    width:20vw;
    text-transform: uppercase;
  }

 
  @media only screen and (max-width: 767px) {
    .title {
      position: absolute;
      font-family: "Moderat";
      font-size: 9.4vw;
      font-weight: 400;
      line-height: 100%;
      letter-spacing:-.094vw;
     
    }
  
    .sub-title {
      position: absolute;
      font-family: "Moderat";
      font-size: 2vw;
      font-weight: 400;
      letter-spacing:.14vw;
      right:4.8vw;
      top:4.8vw;
      width:30vw;
    }

    .sub-title2 {
      position: absolute;
      font-family: "Moderat";
      font-size: 2vw;
      font-weight: 400;
      letter-spacing:.14vw;
      left:12.0vw;
      bottom:9.6vw;
      width:24vw;
    }
  }


      
    
}