.big-divider-7-eleven{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width:100%;
  border:none;
  border-radius: 16px;
  position: relative;
  margin-bottom: 16px;
  height: 11.1vw;
  box-sizing: border-box; 

  @media only screen and (max-width: 767px) {
    border-radius: 3.2vw;
    height: auto;
    
  }


  .title {
    margin:0;
    padding:0;
    font-style: normal;
    font-weight: 700;
    font-size: 6.8vw;
    line-height:100%;
    letter-spacing: -0.02em;
    font-feature-settings: 'ss02' on;
    font-family: 'RightGrotesk';
    margin-bottom: 1vw;
    
    @media only screen and (max-width: 767px) {
      font-size:8.5vw;
      line-height: 8.5vw;
      margin-top:2.2vw;
      margin-bottom:4.2vw;
      text-align: center;
    }
  }

  .text {
    // font-size: 1vw;
    // line-height: 100%;
    // font-feature-settings: 'ss02' on;
    // &.left {
    //   margin-left:48px;
    // }
    // &.right {
    //   margin-right:48px;
    // }

  }

 
      
    
}