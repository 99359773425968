.work-title-block{
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width:100%;
  // height: 640px;
  aspect-ratio: 1190/765;
  flex-shrink: 0; 
  border:none;
  border-radius: 16px;
  position: relative;
  margin-bottom: 16px;
  border: 1px solid #0E0E0E;
  box-sizing: border-box; 

  
  .titles{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-evenly;
    column-gap: 16px;
    margin:0;
    padding:0;
    margin-right:48px;
    margin-left:48px;
    margin-top:24px;


    h1 {
      font-size: 13.5vw;
      margin:0;
      padding:0;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: -0.01em;
      
    }

    .media {
      height: 9.7vw;
    }
  }


  


      
    
}