.rounded-divider{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: calc(100%);
  border:none;
  border-radius: 6.9vw;
  position: relative;
  margin-bottom: 16px;
  height: 8vw;
  box-sizing: border-box; 

  &.radius-16 {
    border-radius: 16px;
  }

  &.meta-ai-narrow {
    height: 10vw;
    @media only screen and (max-width: 767px) {
      height: auto;
      br {
        display: none;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    height: auto;
    br {
      display: none;
    }
  }

  

  .title {
    font-style: normal;
    font-weight: 400;
    font-size: 1.6vw;
    line-height: 2.2vw;
    letter-spacing: -0.02em;
    font-feature-settings: 'ss02' on;
    text-align: center;
    width:92%;

    span{
      font-weight: 500;
      
    }

    .icon {
      padding:0;
      margin: 0;
      height: 1.8vw;
      margin-bottom: -0.3vw;
      margin-left: 0.2vw;
      margin-right: 0.2vw;
    }

    &.sf-pro{
      span{
        font-family: "SF Pro";font: "SF Pro";
        font-weight: 400;
      }
      .icon {
        padding-left:2px; 
        padding-right:2px; 
        height:1.6vw;

        @media only screen and (max-width: 767px) {
          height:4.8vw;
        }
      }


    }

    &.optimistic{
      // font-family: "Optimistic Text";
      span{
        font-family: "Optimistic Text";
        font-weight: 400;
      }
      .icon {
        padding-left:2px; 
        padding-right:2px; 
        height:1.6vw;

        @media only screen and (max-width: 767px) {
          height:4.8vw;
        }
      }

      


    }

    &.meta-ai-narrow {
      width:80%;
      
    }


    @media only screen and (max-width: 767px) {
      font-size: 4.8vw;
      line-height:5.8vw;
      width:86%;
      .icon {
        padding:0;
        margin: 0;
        height: 4.8vw;
        margin-bottom: -0.3vw;
        margin-left: 0.2vw;
        margin-right: 0.2vw;
      }
    }
  }
   
    
}