.image-row{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width:100%;
  position: relative;
  margin-bottom: 16px;
  gap:16px;

 

  &.default-full {
    gap:unset;
    @media only screen and (max-width: 767px) {
      flex-direction: column;
      gap:16px;
    }
  }

  @media only screen and (max-width: 1200px) {
    gap:unset;
  }

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    gap:16px;
  }

  

  .image-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 16px;
    width:calc(33.33% - 10.67px);
    position: relative;
    flex-shrink:0;
    align-self: stretch;
    box-sizing: border-box;

    &.htqCell2  {
      .media {
        video {
          width: 100% !important;
          height: 100% !important;
          left:0% !important;
          margin-top:2vw;
        }
      }
      
    }

    &.small-top {
      flex-direction: column-reverse;
      
    }

    @media only screen and (max-width: 767px) {
      
      width:100%;
      
    }

    &.wide-center{
      margin:0;
      padding:0;
      width:40.48vw;
      @media only screen and (max-width: 767px) {
        width:100%;
      }
    }
    &.narrow{
      margin:0;
      padding:0;
      flex-shrink:1;
      // width:40.48vw;
      @media only screen and (max-width: 767px) {
        width:100%;
      }
    }

    &.full {
      margin:0;
      padding:0;
      flex-shrink:0;
      width:calc(33.33% - 10.67px);
      background: transparent;
      background-color: none;
      // width:40.48vw;
      @media only screen and (max-width: 767px) {
        width:100%;
        min-height: 148vw;
      }
    }

    &.two-column {
      margin:0;
      padding:0;
      flex-shrink:0;
      width:calc(50% - 8px);
      box-sizing: border-box;
      // width:40.48vw;
      @media only screen and (max-width: 767px) {
        width:100%;
        min-height: unset;
      }
    }

    &.three-column {
      margin:0;
      padding:0;
      flex-shrink:0;
      width:calc(33.33% - 10.67px);
      box-sizing: border-box;
      // width:40.48vw;
      @media only screen and (max-width: 767px) {
        width:100%;
        min-height: unset;
      }
    }

    &.rounded {
      border-radius:1.1vw;
      overflow: hidden;
      @media only screen and (max-width: 767px) {
        border-radius:16px;
      }
    }

    img {
      margin:0;
      padding:0;
      z-index:1;
      margin-top:2.22vw;
      margin-bottom:2.22vw;

      max-width:90%;

      &.small-top {
        margin-top:1.2vw;
        margin-bottom:.4vw;
      }

      @media only screen and (max-width: 767px) {
        margin-top:8.4vw;
        margin-bottom:8.4vw;
        max-width: unset;
        max-width:84vw;

        &.small-top {
          margin-top:2.4vw;
          margin-bottom:8vw;
        }
      }

      &.maxWidth80{
        width:18.055vw;
        @media only screen and (max-width: 767px) {
          width:62.5vw; 
        }
        
      }

      &.maxWidth90{
        width:22.055vw;
        @media only screen and (max-width: 767px) {
          width:62.5vw; 
         
        }
        
      }

      &.height90 {
        height:24vw;
        
        @media only screen and (max-width: 767px) {
          // height:70vw;
          height:auto;
          width:80vw;
          margin-top:30vw;
          margin-bottom:30vw;
         
        }
      }

      &.full{
        width:calc(100%);
        max-width: 100%;
        margin-top:0;
        margin-bottom:0;
        @media only screen and (max-width: 767px) {
          width:100%;
          max-width: 100%;
          margin:0;
        }
        
      }
      
      &.wide-center{
        margin:0;
        padding:0;
        width:40.48vw;
        max-width:unset;
        @media only screen and (max-width: 767px) {
          width:100%;
          max-width: 100%;
        }
      }

      &.narrow {
        margin:0;
        padding:0;
        width:16vw;
        @media only screen and (max-width: 767px) {
          width:62.5vw;
          max-width:62.5vw;
          margin-top:8.4vw;
          margin-bottom:8.4vw;
        }
      }

      &.htqVideo3 {
        display: none;
      }
      
    }


  


    .media {

     
      
      &.insetTopBox {
        position: absolute;
        z-index: 1;
        width:6.8vw;
        top:12vw;

        @media only screen and (max-width: 767px) {
          width:24vw;
          top:42vw;
        }
      }

      &.insetMiddleBox {
        position: absolute;
        z-index: 0;
        width:18vw;
        margin-top:3vw;

        @media only screen and (max-width: 767px) {
          width:87.8vw;
          top:34vw;
        }
      }

      &.complex {
        display: flex;
        justify-content: center;
        align-items: center;

        

        .media-image {
          position: absolute;
          margin:0;
          padding:0;
          z-index: 0;
          width: 16.5vw;
          border-radius: 32px;
          overflow: hidden;
          top:2.95vw;
          @media only screen and (max-width: 767px) {
            top:8.8vw;
            width:58vw; 
          }
        }

        .media-image-2 {
          position: absolute;
          width: 100%;
          margin:0;
          padding:0;
          z-index: 1;
          width:14vw;
          top:14vw;
          @media only screen and (max-width: 767px) {
            top:50vw;
            width:48vw; 
          }
        }

      }

      &.videoOverlay {
        padding:0;
        margin:0;
        position: absolute;
        z-index: 0;
        width: 16.4vw;
        border-radius: 32px;
        overflow: hidden;
        
        @media only screen and (max-width: 767px) {
          width:61%;
        }
       

        video{
          padding:0;
          margin:0;
          width:100%;
          
        }
      }

     

      &.videoOverlayFull {
        padding:0;
        margin:0;
        position: absolute;
        z-index: 0;
        width: calc(100% - 2.7vw);
        border-radius: 32px;
        overflow: hidden;
        
        @media only screen and (max-width: 767px) {
          width: calc(100% - 2.8vw);
          position: relative;
        }
       

        video{
          padding:0;
          margin:0;
          width:100%;
          
        }




      }


      &.htqVideo {
       
        video {
          width: 130%;
          
          height:130%;
          position: relative;
          top:-15%;
          left:-15%;
          object-fit: cover;
        }
      }

      &.htqVideo2 {
       
        video {
          width: 100%;
          height:100%;
          margin-top:0;
          position: relative;
          top:0%;
          left:0%;
          object-fit: cover;
        }
      }


      &.htqVideo3 {
        margin-top:1.2vw;
        margin-bottom:2.4vw;
        
        @media only screen and (max-width: 767px) {
          margin-top:2.4vw;
          margin-bottom:8.4vw;
        }
        video {
          width: 100%;
          height:100%;
          position: relative;
          top:0%;
          left:0%;
          object-fit: contain;
        }
      }

      

      &.narrow {
        width: 14.6vw;
        @media only screen and (max-width: 767px) {
          width:61%;
        }
      }
    }
    
  }
    
}