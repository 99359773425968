.media-block-7-eleven{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width:100%;
  // height: 640px;
  // aspect-ratio: 1190/720;
  // flex-shrink: 1; 
  // flex-grow: 1;
  border:none;
  border-radius: 16px;
  position: relative;
  margin-bottom: 16px;
  // border: 1px solid #0E0E0E;
  position: relative;
  box-sizing: border-box; 


  .media-block-content-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &.full-bleed{
    flex-shrink: unset; 

  }

  &.tight {
    aspect-ratio: unset;
  }

  .lf-player-container {
    width:inherit;
  }
  
  h1 {
    margin:0;
    padding:0;
    font-size:8.3vw;
    font-weight: 400;
    margin-bottom:-4vw;
  }

  .media {
    // width:82%;
    // height: auto;
    // margin-top:1.666vw;

    &.left {
      padding:0;
      margin:0;
      width:25vw;
      margin-left:48px;
    }

    &.right50{
      margin-left: 2vw;
      width:50vw;
    }

    &.tight {

    }

    &.larger {
      width:91%;
      margin-top:0px;
    }
    &.full {
      width:calc(100% - 96px);
      padding:0;
      margin: 0;
      margin-left:48px;
      margin-right:48px;
    }
    &.full-bleed {
      width:calc(100%);
      object-fit: cover;
      padding:0;
      margin: 0;
      
    }
    &.topPad {
      
      margin-top:4.44vw;
    }

    &.bottomPad {
      margin-bottom:4.44vw;
    }
    &.topPadLarger {
      
      margin-top:9vw;
    }

    &.bottomPadLarger {
      margin-bottom:9vw;
    }
    &.offset-top{
      margin-top:-11vw;
    }

    &.offset-top-big{
      margin-top:-30vw;
    }
    &.inset-80 {
      width:80vw;
    }
  }


  

  

  


      
    
}