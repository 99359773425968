.robot-7-eleven{
  position: relative;
  width:100%;
  z-index: 1000;
  .robot{
    position: absolute;
    z-index:1000;
    width:20vw;
  }
    

}