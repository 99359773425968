.quotes{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width:100%;
  position: relative;
  margin-bottom: 16px;
  gap:24px;
  box-sizing: border-box; 

  .quote-card {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    border-radius: 16px;
    border: 1px solid #0E0E0E;
    width:100%;
    position: relative;
    box-sizing: border-box; 

    @media only screen and (max-width: 767px) {
      display:none;
    }


    .row{
      display: flex;
      justify-content: space-between;
      align-items: top;
      flex-direction: row;
      margin-left:48px;
      width:calc(100% - 96px);
      border-bottom:1px solid #0E0E0E;
      padding-bottom:24px;
      padding-top:24px;
      box-sizing: border-box; 
      

      &.last-row {
        border-bottom:none;
        padding-bottom: 48px;
      }

      .media {
        margin:0;
        padding:0;
        width:18vw;
        box-sizing: border-box; 
        flex-shrink: 0;
        &.image {
          width:10vw;
          margin-right:8vw;
        };
        
      }



      .client {
        margin:0;
        padding:0;
        font-size:2vw;
        line-height: 100%;
        font-weight: 400;
        width:11vw;
        margin-top:24px;
        
      }

      .title {
        margin:0;
        padding:0;
        font-size:2vw;
        line-height: 100%;
        font-weight: 400;
        width:11vw;
        margin-top:24px;
      }

      .quote {
        margin:0;
        padding:0;
        font-size:2vw;
        line-height: 100%;
        font-weight: 400;
        width:30vw;
        margin-top:24px;
        margin-bottom:1.2vw;
      }
      
      .tags{
        display: flex;
        justify-content: start;
        align-items: center;
        flex-direction: row;
        gap:8px;

        span{
          border: 1px solid #0E0E0E;
          border-radius: 24px;
          padding:12px;
          padding-top:6px;
          padding-bottom: 6px;
          font-size:1vw;
          text-transform: uppercase;

          @media only screen and (max-width: 1023px) {
            font-size:1.3w;
            padding-top:4px;
            padding-bottom: 4px;
          }

        }
      }

      .attribute {
        position: absolute;
        right:48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        
        width:30vw;
        margin-bottom:1.2vw;

        .attribute-by{
          border: 1px solid #0E0E0E;
          border-radius: 24px;
          padding:12px;
          padding-top:6px;
          padding-bottom: 6px;
          font-size:1vw;
          text-transform: uppercase;

          @media only screen and (max-width: 1023px) {
            font-size:1.3w;
            padding-top:4px;
            padding-bottom: 4px;
          }
        }

        .attribute-name {
          font-size: 2vw;
        }

        .attribute-info {
          display: flex;
          justify-content: start;
          align-items: start;
          flex-direction: column;
          font-size:.83vw;

          @media only screen and (max-width: 1023px) {
            font-size:1.3w;

          }
        }

      }
  
  
    }

    
  }





  /* Mobile special layout */

  .quote-card-mobile {
    display: none;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    border-radius: 16px;
    border: 1px solid #0E0E0E;
    width:100%;
    position: relative;
    
    @media only screen and (max-width: 767px) {
      display:flex;
    }

    .row{
      display: flex;
      justify-content: space-between;
      align-items: top;
      flex-direction: row;
      margin-left:4.2vw;
      width:calc(100% - 8.4vw);
      padding-bottom:4.2vw;;
      padding-top:2.1vw;

      &.last-row {
        
        border-top:1px solid #0E0E0E;
      }

      .media {
        margin:0;
        padding:0;
        width:100%;


        &.image {
          width:70%;
          margin-left:15%;
          margin-top:8.4vw;
          margin-bottom:4.8vw;
        };
        
      }



      .client {
        margin:0;
        padding:0;
        font-size:5.3vw;
        line-height: 100%;
        font-weight: 400;
        width:35vw;
        // padding-right:5vw;

        
      }

      .title {
        margin:0;
        padding:0;
        font-size:5.3vw;
        line-height: 100%;
        font-weight: 400;
        width:38vw;
        padding-right:0vw;
        
      }

      .quote {
        margin:0;
        padding:0;
        font-size:5.3vw;
        line-height: 120%;
        font-weight: 300;
        margin-top:4.2vw;
        width:calc(100% - 4.2vw);
       
       
      }

      &.attribute {
        
        
        display: flex;
        justify-content: space-between;
        align-items: start;
        flex-direction: row;
        width:calc(100% - 8.4vw);

        .attribute-by{
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #0E0E0E;
          border-radius: 6.4vw;
          text-transform: uppercase;
          height:4.2vw;
          padding-left:2vw;
          padding-right: 2vw;
          font-size:3.2vw;
          margin-top: 2.4vw;
          
        }

        .attribute-name-column {
          width:34.6vw;
          padding-right:2.4vw;

          .attribute-name {
            font-size: 5.3vw;
          }
  
          .attribute-info {
            display: flex;
            justify-content: start;
            align-items: start;
            flex-direction: column;
            font-size:3.2vw;
          }

        }

        

      }
      
      .tags{
        display: flex;
        justify-content: start;
        align-items: center;
        flex-direction: row;
        gap:8px;
        flex-wrap: wrap;
        margin-bottom:4.8vw;
        margin-top:2.4vw;
        span{
          display: flex;
          align-items: center;
          flex-shrink: 0;
          border: 1px solid #0E0E0E;
          border-radius: 6.4vw;
          padding-top:1.2vw;
          padding-bottom: 1.2vw;
          padding-left:2.4vw;
          padding-right: 2.4vw;
          font-size:3.2vw;
          text-transform: uppercase;

     

        }
      }
  
    }

    
  }
      
    
}