.big-divider-volta{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width:100%;
  border:none;
  border-radius: 16px;
  position: relative;
  box-sizing: border-box; 
  margin-bottom: 16px;
  height: 28vw;

  @media only screen and (max-width: 767px) {
    border-radius: 3.2vw;
    height: auto;
    
  }


  .title {
    margin:0;
    padding:0;
    font-style: normal;
    font-weight: 400;
    font-size: 18vw;
    letter-spacing: -0.02em;
    font-feature-settings: 'ss02' on;
    font-family: "KlarheitKurrent";
    
    @media only screen and (max-width: 767px) {
      font-size:20vw;
      
      margin-top:2.2vw;
      margin-bottom:4.2vw;
      text-align: center;
    }
  }

 .icon {
  height: 14vw;
  margin:0;
  padding:0;
  margin-left:4.2vw;
  @media only screen and (max-width: 767px) {
    height: 16vw;
    padding-bottom:4vw;
  }
 }
 
      
    
}